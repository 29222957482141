import React, { useEffect } from 'react';
import { Widget } from '@typeform/embed-react';
import { X } from 'lucide-react';

interface TypeFormModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const TypeFormModal: React.FC<TypeFormModalProps> = ({ isOpen, onClose }) => {
  // Handle ESC key press
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEsc);
    }

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen, onClose]);

  // Prevent scroll on body when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50"
      onClick={onClose}
    >
      <div 
        className="relative w-full max-w-4xl h-[80vh] bg-[#444444] rounded-lg"
        onClick={e => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute right-4 top-4 z-50 p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors duration-200"
          aria-label="Close modal"
        >
          <X className="w-6 h-6 text-white" />
        </button>
        <div className="w-full h-full rounded-lg overflow-hidden">
          <Widget id="aUOuhTAX" className="w-full h-full" />
        </div>
      </div>
    </div>
  );
};

export default TypeFormModal;