import React, { useState } from 'react';
import { SocialMediaLink } from '../types';

interface SocialMediaInputProps {
  onAdd: (link: Omit<SocialMediaLink, 'id'>) => void;
}

const SocialMediaInput: React.FC<SocialMediaInputProps> = ({ onAdd }) => {
  const [url, setUrl] = useState('');
  const [type, setType] = useState<SocialMediaLink['type']>('instagram');
  const [error, setError] = useState('');

  const validateUrl = (url: string, type: SocialMediaLink['type']): boolean => {
    try {
      const parsedUrl = new URL(url);
      
      switch(type) {
        case 'instagram':
          return /instagram\.com\/p\/[\w-]+/.test(parsedUrl.href);
        case 'twitter':
          return /x\.com\/\w+\/status\/\d+/.test(parsedUrl.href);
        case 'youtube':
          return (
            /youtube\.com\/watch\?v=[\w-]+/.test(parsedUrl.href) ||
            /youtu\.be\/[\w-]+/.test(parsedUrl.href)
          );
        case 'tiktok':
          return /tiktok\.com\/@[\w.-]+\/video\/\d+/.test(parsedUrl.href);
        default:
          return false;
      }
    } catch {
      return false;
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    const trimmedUrl = url.trim();
    if (!trimmedUrl) {
      setError('Please enter a URL');
      return;
    }

    if (!validateUrl(trimmedUrl, type)) {
      setError(`Invalid ${type} URL format`);
      return;
    }

    onAdd({ url: trimmedUrl, type });
    setUrl('');
  };

  const getPlaceholder = () => {
    switch(type) {
      case 'instagram':
        return 'https://instagram.com/p/ABC123';
      case 'twitter':
        return 'https://x.com/username/status/123456';
      case 'youtube':
        return 'https://youtube.com/watch?v=ABC123';
      case 'tiktok':
        return 'https://tiktok.com/@username/video/123456';
      default:
        return 'Enter social media URL';
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col space-y-2 mb-4">
      <div className="flex flex-col sm:flex-row gap-2">
        <select
          value={type}
          onChange={(e) => {
            setType(e.target.value as SocialMediaLink['type']);
            setError('');
          }}
          className="p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="instagram">Instagram</option>
          <option value="twitter">Twitter</option>
          <option value="youtube">YouTube</option>
          <option value="tiktok">TikTok</option>
        </select>
        
        <div className="flex-1">
          <input
            type="url"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
              setError('');
            }}
            placeholder={getPlaceholder()}
            className={`w-full p-2 border rounded focus:outline-none focus:ring-2 ${
              error ? 'border-red-500 focus:ring-red-500' : 'focus:ring-blue-500'
            }`}
          />
          {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>

        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          Add
        </button>
      </div>
    </form>
  );
};

export default SocialMediaInput;