import React, { useState, useEffect, useCallback, useRef } from 'react';
import { League } from '../services/firebase';
import { SeasonRecord } from '../types/yahoo';
import { useYahooAuth } from '../contexts/YahooAuthContext';
import { createYahooSeasonHandler } from '../services/yahoo-season-records';
import { Trophy, Award, Frown } from 'lucide-react';

interface LeagueSeasonDisplayProps {
  league: League;
  updateLeague: (id: string, updatedLeague: Partial<League>) => Promise<void>;
}

const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours

const LeagueSeasonDisplay: React.FC<LeagueSeasonDisplayProps> = ({ league, updateLeague }) => {
  const [currentSeason, setCurrentSeason] = useState<SeasonRecord | null>(null);
  const [previousSeason, setPreviousSeason] = useState<SeasonRecord | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { yahooToken, refreshYahooToken } = useYahooAuth();
  const isFetchingRef = useRef(false);

  const isCacheValid = useCallback(() => {
    if (!league.recordBookLastUpdated) return false;
    const now = Date.now();
    return (
      now - league.recordBookLastUpdated < CACHE_DURATION && 
      league.recordBook && 
      league.recordBook.length > 0
    );
  }, [league.recordBookLastUpdated, league.recordBook]);

  const loadCachedData = useCallback(() => {
    if (league.recordBook && league.recordBook.length > 0) {
      const sortedRecords = [...league.recordBook].sort((a, b) => 
        parseInt(b.season) - parseInt(a.season)
      );
      setCurrentSeason(sortedRecords[0]);
      setPreviousSeason(sortedRecords[1]);
      return true;
    }
    return false;
  }, [league.recordBook]);

  useEffect(() => {
    const fetchSeasonData = async () => {
      // Prevent concurrent fetches
      if (isFetchingRef.current) return;
      
      // Check if we have valid cached data
      if (isCacheValid()) {
        console.log('Using cached season data');
        loadCachedData();
        setIsLoading(false);
        return;
      }

      // Check if we have the required data to make the request
      if (!league?.yahooLeague?.league_key || !yahooToken) {
        setIsLoading(false);
        return;
      }

      try {
        isFetchingRef.current = true;
        console.log('Fetching fresh season data');
        
        const seasonHandler = createYahooSeasonHandler(yahooToken, refreshYahooToken);
        const records = await seasonHandler.getAllSeasonRecords(league.yahooLeague.league_key);

        if (records.length > 0) {
          const sortedRecords = records.sort((a, b) => 
            parseInt(b.season) - parseInt(a.season)
          );

          setCurrentSeason(sortedRecords[0]);
          setPreviousSeason(sortedRecords[1]);

          // Only update league data if it's different from what we already have
          if (league.id && (!league.recordBook || JSON.stringify(league.recordBook) !== JSON.stringify(sortedRecords))) {
            await updateLeague(league.id, {
              recordBook: sortedRecords,
              recordBookLastUpdated: Date.now()
            });
          }
        }
      } catch (err) {
        console.error('Error fetching season data:', err);
        setError('Failed to fetch season data');
        
        // Try to load cached data as fallback
        if (league.recordBook && league.recordBook.length > 0) {
          console.log('Loading cached data after error');
          loadCachedData();
        }
      } finally {
        setIsLoading(false);
        isFetchingRef.current = false;
      }
    };

    fetchSeasonData();
  }, [
    league?.id,
    league?.yahooLeague?.league_key,
    yahooToken,
    refreshYahooToken,
    updateLeague,
    isCacheValid,
    loadCachedData
  ]);

  const formatRecord = useCallback((wins: number, losses: number, ties?: number) => {
    const record = `${wins}-${losses}`;
    return ties && ties > 0 ? `${record}-${ties}` : record;
  }, []);

  const SeasonCard = useCallback(({ season, isPrevious = false }: { season: SeasonRecord | null, isPrevious?: boolean }) => {
    if (!season) return null;

    const championRecord = formatRecord(
      Number(season.wins), 
      Number(season.losses), 
    );

    const loserRecord = formatRecord(
      Number(season.lastPlace.wins), 
      Number(season.lastPlace.losses),
    );

    return (
      <div className="bg-gray-50 p-6 rounded-lg">
        <h2 className="text-2xl font-semibold mb-4 flex items-center gap-2">
          {isPrevious ? 'Previous Season' : 'Current Season Projections'} ({season.season})
          <Trophy className="text-[#18f7b1]" />
        </h2>
        
        <div className="space-y-6">
          <div className="border-l-4 border-[#18f7b1] pl-4">
            <div className="flex items-center gap-2 mb-2">
              <Award className="text-[#18f7b1]" />
              <h3 className="text-xl font-semibold">
                {isPrevious ? 'Defending Champion' : 'Champion'}
              </h3>
            </div>
            <div className="space-y-1">
              <p className="font-bold text-lg">{season.name}</p>
              <p>Record: {championRecord}</p>
              <p>Points: {Number(season.points_for).toFixed(2)}</p>
            </div>
          </div>

          <div className="border-l-4 border-red-500 pl-4">
            <div className="flex items-center gap-2 mb-2">
              <Frown className="text-red-500" />
              <h3 className="text-xl font-semibold">
                {isPrevious ? 'Defending Loser' : 'Last Place'}
              </h3>
            </div>
            <div className="space-y-1">
              <p className="font-bold text-lg">{season.lastPlace.name}</p>
              <p>Record: {loserRecord}</p>
              <p>Points: {Number(season.lastPlace.points).toFixed(2)}</p>
            </div>
          </div>

          <div className="mt-4">
            <p className="text-sm text-gray-600">
              Highest Scoring Team: {season.highestScorer.name}
              {' '} ({Number(season.highestScorer.points).toFixed(2)} pts)
            </p>
          </div>
        </div>
      </div>
    );
  }, [formatRecord]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-32">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-[#18f7b1]"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <SeasonCard season={currentSeason} />
        <SeasonCard season={previousSeason} isPrevious />
      </div>
    </div>
  );
};

export default LeagueSeasonDisplay;