import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import ContentCarousel from '../components/ContentCarousel';
import { getRandomContent, getWeeklyChallenges } from '../services/firebase';
import { ContentItem,WaitlistFormData } from '../types';
import { WeeklyChallenge } from '../components/WeeklyChallengeForm';
import Navbar from '../components/Navbar';
import ComingSoonSection from '../components/ComingSoon';
import TypeFormModal from '../components/ui/TypeFormModal';
import { useModal } from '../contexts/ModalContext';
import { ArrowRight, ArrowUpRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import Slider from '../components/ui/Slider';
import LeagueRegistrationSlider from '../components/ui/LeagueRegistrationSlider';
import MetaHead from '../components/ui/MetaHead';
import { homePageMeta } from '../components/ui/MetaHead';

// Custom hook for measuring element height
const useHeight = ({ on = true } = {}) => {
  const [height, setHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!on || !ref.current) return;
    
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setHeight(entry.contentRect.height);
      }
    });

    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, [on]);

  return { height, ref };
};

const truncateText = (text: string, limit: number) => {
  if (text.length <= limit) return text;
  return text.slice(0, limit) + '...';
};


// Helper component for the rotated arrow
const ArrowIcon: React.FC<{ className?: string }> = ({ className = "w-4 h-4" }) => (
  <svg 
    viewBox="0 0 24 24" 
    fill="none" 
    className={className}
    stroke="currentColor"
    strokeWidth={2.5}
  >
    <path 
      d="M7 17L17 7M17 7H8M17 7V16" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
  </svg>
);

const AccordionItem: React.FC<{
  challenge: WeeklyChallenge;
  isExpanded: boolean;
  onToggle: () => void;
  onViewDetails: () => void;
}> = ({ challenge, isExpanded, onToggle, onViewDetails }) => {
  const { height, ref } = useHeight({ on: isExpanded });


  return (
    <div className="bg-[#1A1A1A] border-white/40 border-b-[1px] w-full mt-4"> 
      <div className="relative min-h-[70px] sm:min-h-[95px] flex items-center"> 
        {/* Week tag */}
        <div 
          className={`
            absolute left-4 sm:left-6 -top-3 transition-all duration-300 z-20
            ${isExpanded ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4 pointer-events-none'}
          `}
        >
          <div className="bg-[#18f7b1] text-black px-3 sm:px-4 py-1 rounded-md 
                       text-sm sm:text-md font-bold text-center font-antonio">
            Week {challenge.week}
          </div>
        </div>

        <button
          onClick={onToggle}
          className="w-full flex items-center justify-between text-white 
                   hover:bg-white/5 transition-colors min-h-[70px] sm:min-h-[95px]
                   pr-[48px] sm:pr-[64px]" // Added right padding to prevent text overlap
        >
          <h4 className="text-2xl sm:text-[32px] md:text-[48px] font-antonio 
                       pl-4 sm:pl-6 leading-tight line-clamp-2 sm:line-clamp-1">
            {challenge.name}
          </h4>
        </button>
        
        <button
          onClick={onViewDetails}
          className="absolute right-4 sm:right-6 top-1/2 -translate-y-1/2
                   bg-[#18f7b1] p-1.5 sm:p-2 rounded-md text-black z-10
                   hover:scale-110 transition-transform duration-200"
          aria-label="View details"
        >
          <ArrowIcon className="w-3 h-3 sm:w-4 sm:h-4" />
        </button>
      </div>
      
      <div 
        className="overflow-hidden transition-[height] duration-300 ease-in-out"
        style={{ height: isExpanded ? height : 0 }}
      >
        <div ref={ref}>
        <div className="px-4 sm:px-6 py-2 text-white/80">
  <p className="text-base sm:text-[18px] md:text-[24px] font-roboto whitespace-pre-wrap break-words">
    {truncateText(challenge.subtitle || challenge.description || '', 300)}
  </p>
</div>
        </div>
      </div>
    </div>
  );
};

const HomePage: React.FC = () => {
  const [carouselItems, setCarouselItems] = useState<ContentItem[]>([]);
  const [challenges, setChallenges] = useState<WeeklyChallenge[]>([]);
  const [selectedChallenge, setSelectedChallenge] = useState<WeeklyChallenge | null>(null);
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const navigate = useNavigate();
  const { isTypeFormOpen, closeTypeForm } = useModal();
  const [isMobile, setIsMobile] = useState(false);
  const [isNewsletterOpen, setIsNewsletterOpen] = useState(false);
  const [isRegistrationOpen, setIsRegistrationOpen] = useState(false);

// Add this useEffect hook to detect mobile screens
useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  // Set initial value
  handleResize();

  // Add event listener
  window.addEventListener('resize', handleResize);

  // Cleanup
  return () => window.removeEventListener('resize', handleResize);
}, []);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const content = await getRandomContent(20);
        setCarouselItems(content);
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    };
    fetchContent();
  }, []);

  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        const fetchedChallenges = await getWeeklyChallenges();
        const sortedChallenges = fetchedChallenges.sort((a, b) => {
          const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
          const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
          return dateB - dateA;
        });
        setChallenges(sortedChallenges);
        if (sortedChallenges.length > 0) {
          setSelectedChallenge(sortedChallenges[0]);
          if (sortedChallenges[0].id) {
            setExpandedId(sortedChallenges[0].id);
          }
        }
      } catch (error) {
        console.error('Error fetching challenges:', error);
      }
    };
    fetchChallenges();
  }, []);

  const handleItemClick = (item: ContentItem ) => {
    if ('type' in item) {
      navigate(`/punishments/${item.id}`);
    } else {
      navigate(`/`);
    }
  };

  const handleChallengeClick = (challenge: WeeklyChallenge) => {
    setSelectedChallenge(challenge);
    if (challenge.id) {
      setExpandedId(expandedId === challenge.id ? null : challenge.id);
    }
  };

  const handleViewChallengeClick = (challengeId: string | undefined) => {
    if (challengeId) {
      navigate(`/challenges/${challengeId}`);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-white text-black flex-grow">
     <MetaHead
        title={homePageMeta.title}
        description={homePageMeta.description}
        keywords={homePageMeta.keywords}
      />
      <Navbar />
      
      <div className="bg-[#1A1A1A]">
      <header className="relative w-full  mx-auto mt-[-40px]">
  <div className="relative">
      {/* Mobile-optimized height */}
  <div className="relative w-full h-[550px] md:h-[750px] lg:h-[750px] xl:h-[750px] mt-6">
    {/* Image container with mobile-specific object positioning */}
    <div className="absolute inset-0">
      {/* Image for small to medium screens */}
      <img
        className="w-full h-full object-cover md:object-cover lg:hidden"
        src={require("../assets/headers/1440 x 730.png")}  // Default image for screens <= 1440px
        alt="Fantasy Sports Background"
      />

      {/* Image for large screens > 1440px */}
      <img
        className="hidden lg:block w-full h-full object-cover"
        src={require("../assets/headers/1920 x 730.png")} // Image for large screens
        alt="Fantasy Sports Background Large"
      />
    </div>

      {/* Gradient overlays */}
      <div className="absolute inset-0 bg-black/40 md:hidden" /> {/* Mobile-only overlay */}

      {/* Content container */}
      <div className="absolute inset-0 mx-auto max-w-[1440px] h-full flex items-center px-4 sm:px-12 mt-[4%]">
        {/* Text content */}
        <div className="space-y-4">
          <h1 className="text-8xl sm:text-6xl md:text-9xl font-antonio font-bold text-white leading-tight">
            Losing is <br />
            Winning
          </h1>

          <p className="text-2xl md:text-3xl text-[white] font-thin font-antonio">
            When you lose to your friends
          </p>

          {/* Buttons container */}
          <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 mt-4 sm:mt-10">
            <button
              onClick={() => setIsRegistrationOpen(true)}
              className="text-white text-center text-lg sm:text-xl md:text-2xl w-full sm:w-auto sm:max-w-[220px] font-antonio bg-transparent border border-primary-500 hover:bg-black/60 hover:text-white transition-colors duration-300 py-2 sm:py-4 px-4 sm:px-8 rounded-md"
            >
              Join The Waitlist
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

    </div>



      <main className="flex-grow flex flex-col">
        <br/>
        <br/>

      {/* Top Punishments Section */}
<section className="w-full px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
  <div className="max-w-[1440px] mx-auto">
    {/* Header Section - Changed to flex column with center alignment */}
    <div className="flex flex-col items-center text-center mb-12 lg:mb-16">
      {/* Title */}
      <h2 className="text-4xl sm:text-5xl lg:text-7xl font-bold leading-none font-antonio text-[#1a1a1a] mb-6 lg:mb-8 uppercase">
        Top Loser
        Punishments
      </h2>

      {/* Description - Centered and larger */}
      <p className="text-xl sm:text-2xl lg:text-4xl mb-8 lg:mb-10 font-antonio text-[#1A1A1A] max-w-4xl">
        Browse our library for the ultimate punishment inspiration.
      </p>

      {/* Buttons Container - Added flex row container */}
<div className="flex flex-col sm:flex-row gap-4 justify-center">
  <button
    onClick={() => navigate('/explore')}
    className="w-full sm:w-auto bg-white border-2 border-black text-black px-8 sm:px-10 py-4 sm:py-5 
             rounded-[10px] hover:bg-black hover:text-white transition-colors duration-300
             font-source text-lg sm:text-xl lg:text-2xl min-w-[200px]"
  >
    View Punishments
  </button>

  <Link
    to='https://h4hcbpgn1q9.typeform.com/to/E21Gfvu6'
    className="w-full sm:w-auto bg-black border-2 border-black text-white px-8 sm:px-10 py-4 sm:py-5 
             rounded-[10px] hover:bg-white hover:text-black transition-colors duration-300
             font-source text-lg sm:text-xl lg:text-2xl min-w-[200px]
             flex items-center justify-center gap-2"
  >
    <span>Submit a Punishment</span>
    <ArrowUpRight className="w-6 h-6" />
  </Link>
</div>
    </div>

    {/* Carousel Section */}
    <div className="relative mt-8 sm:mt-12">
      <ContentCarousel
        items={carouselItems}
        onItemClick={handleItemClick}
      />
    </div>
  </div>
</section>

        {/* Weekly Challenge Section */}
<section className="bg-[#1A1A1A] py-16 relative">
  <div className="container mx-auto px-4">
    {/* Header content without buttons */}
    <div className="flex flex-col items-center text-center mb-12 lg:mb-16">
      <h2 className="text-4xl sm:text-5xl lg:text-7xl font-bold leading-none font-antonio text-white mb-6 lg:mb-8 uppercase">
        The Weekly Challenge
      </h2>

      <p className="text-xl sm:text-2xl lg:text-4xl mb-8 lg:mb-10 font-antonio text-white/90 max-w-4xl">
        Boost your league's fun with Fantasy Loser weekly in-season challenges
        — or create your own from our library!
      </p>
    </div>

    {/* Main content grid */}
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 overflow-hidden">
      {/* Image Column */}
      <div className="relative group w-full">
        {selectedChallenge && selectedChallenge.id && (
          <div className="relative h-[400px] sm:h-[600px] lg:h-[846px] w-full 
                       overflow-hidden rounded-xl transition-transform duration-300 
                       group-hover:scale-[1.02]">
            <div className="absolute inset-0 bg-gradient-to-b from-black/40 via-black/20 to-black/90 z-10" />
            <img 
              src={typeof selectedChallenge.image === 'string' ? selectedChallenge.image : '/placeholder-image.jpg'}
              alt={selectedChallenge.name || 'Selected challenge'}
              className="w-full h-full object-cover"
            />
            <div className="absolute font-roboto top-4 sm:top-6 left-4 sm:left-6 z-20 flex items-center space-x-4">
              <div className="bg-[#18f7b1] text-black px-3 py-1 rounded-md text-sm sm:text-md font-antonio font-bold">
                Week {selectedChallenge.week}
              </div>
            </div>
            <h3 className="absolute bottom-4 sm:bottom-6 left-4 sm:left-6 text-3xl sm:text-[48px] 
                        font-antonio text-white/90 z-20 leading-tight max-w-[90%]">
              {selectedChallenge.name}
            </h3>
          </div>
        )}
      </div>

      {/* Accordion Column with Buttons */}
      <div className="flex flex-col h-auto lg:h-[846px] w-full relative"> 
        {/* Accordion Items */}
        <div className="flex flex-col w-full "> 
          <div className="space-y-0">
            {challenges
              .slice(0, 5)
              .map((challenge) => (
                <AccordionItem
                  key={challenge.id || Math.random().toString()}
                  challenge={challenge}
                  isExpanded={expandedId === challenge.id}
                  onToggle={() => handleChallengeClick(challenge)}
                  onViewDetails={() => handleViewChallengeClick(challenge.id)}
                />
              ))}
          </div>
        </div>

        {/* Buttons Container - Moved here and made smaller */}
        <div className="flex flex-col sm:flex-row gap-3 mt-6">
          <button
            onClick={() => navigate('/challenges')}
            className="w-full sm:w-auto px-4 sm:px-6 py-2 sm:py-3
                     text-white border-2 border-white rounded-lg
                     hover:bg-white hover:text-[#1A1A1A] transition-all duration-300 
                     font-source text-base sm:text-lg"
          >
            View Challenges
          </button>

          <Link
            to='https://h4hcbpgn1q9.typeform.com/to/oDKBcyJn'
            className="w-full sm:w-auto px-4 sm:px-6 py-2 sm:py-3
                     text-black bg-white border-2 border-white rounded-lg
                     hover:bg-[#1a1a1a] hover:text-white transition-all duration-300 
                     font-source text-base sm:text-lg
                     flex items-center justify-center gap-2"
          >
            <span>Submit a Challenge</span>
            <ArrowUpRight className="w-4 h-4 sm:w-5 sm:h-5" />
          </Link>
        </div>
      </div>
    </div>
  </div>
</section>

        {/* Coming Soon Section */}
        <ComingSoonSection />

              {/* New Section - Connected Platforms */}
              <section className="container mx-auto px-4 mb-12 text-center mt-10">
  {/* Title */}
  <h2 className="text-4xl sm:text-5xl lg:text-6xl font-bold justify-center font-antonio text-black mb-20 uppercase">
    Connect Your Fantasy Leagues
  </h2>

  {/* Image Grid */}
  <div className="flex flex-col sm:flex-row justify-center items-center space-y-8 sm:space-y-0 sm:space-x-8 mb-10 sm:gap-20">
    {/* Image 1 */}
    <div className="relative group">
      <div className="relative w-40 sm:w-48 overflow-hidden rounded-lg transition-transform duration-300 group-hover:scale-[1.2]">
        <Link to="https://yahoo.com" target="_blank" rel="noopener noreferrer">
        <img 
          src={require("../assets/yahoo.png")}
          alt="Yahoo"
          className="w-full h-auto object-contain"
        />
        </Link>
      </div>
    </div>

    {/* Image 2 */}
    {/* <div className="relative group">
      <div className="relative w-40 sm:w-48 overflow-hidden rounded-lg transition-transform duration-300 group-hover:scale-[1.2]">
      <Link to="https://espn.com" target="_blank" rel="noopener noreferrer">
        <img 
          src={require("../assets/ESPN_wordmark.png")}
          alt="ESPN"
          className="w-full h-auto object-contain"
        />
        </Link>
      </div>
    </div> */}

    {/* Image 3 */}
    <div className="relative group">
      <div className="relative w-48 sm:w-60 overflow-hidden rounded-lg transition-transform duration-300 group-hover:scale-[1.2]">
      <Link to="https://sleeper.com" target="_blank" rel="noopener noreferrer">
        <img 
          src={require("../assets/sleeper.png")}
          alt="Sleeper"
          className="w-full h-auto object-contain"
        />
        </Link>
      </div>
    </div>
  </div>
</section>

        <TypeFormModal 
        isOpen={isTypeFormOpen} 
        onClose={closeTypeForm} 
      />
      </main>

      <Slider
      isOpen={isNewsletterOpen}
      onClose={() => setIsNewsletterOpen(false)} />\
      <LeagueRegistrationSlider 
  isOpen={isRegistrationOpen}
  onClose={() => setIsRegistrationOpen(false)}
/>

<Footer />
    </div>
  );
};

export default HomePage;