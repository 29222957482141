import React, { useState, useEffect, useRef } from 'react';
import { Plus, Minus, Calculator, Save, X, AlertTriangle, Camera } from 'lucide-react';
import { 
  addDoc, 
  collection, 
  doc, 
  updateDoc, 
  getDoc,
  serverTimestamp 
} from 'firebase/firestore';
import { db, storage } from '../../services/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// Types and interfaces
interface Stake {
  title: string;
  description: string;
}

interface PlayerStat {
  position: string;
  positionRank: number;
  statId: string;
}

interface FormulaStep {
  type: 'stat' | 'operator' | 'number';
  value: string | number;
  playerStat?: PlayerStat;
}

interface FormulaGroup {
  name: string;
  description: string;
  steps: FormulaStep[];
}

interface WeekOption {
  value: number;
  label: string;
  status: 'upcoming' | 'current' | 'completed';
}

interface ChallengeCalculatorProps {
  challengeId?: string;
  onSaveSuccess?: () => void;
}

// Available stats from Yahoo API
export const availableStats = [
  // QB total points
  { id: 'qb_pts', name: 'QB Fantasy Points', category: 'QB Total' },
  
  // RB total points
  { id: 'rb_pts', name: 'RB Fantasy Points', category: 'RB Total' },
  
  // WR total points
  { id: 'wr_pts', name: 'WR Fantasy Points', category: 'WR Total' },
  
  // TE total points
  { id: 'te_pts', name: 'TE Fantasy Points', category: 'TE Total' },
  
  // K total points
  { id: 'k_pts', name: 'K Fantasy Points', category: 'K Total' },
  
  // DEF total points
  { id: 'def_pts', name: 'DEF Fantasy Points', category: 'DEF Total' },
  
  // Existing stats
  { id: '4', name: 'Passing Yards', category: 'Passing' },
  { id: '5', name: 'Passing TDs', category: 'Passing' },
  { id: '6', name: 'Interceptions', category: 'Passing' },
  { id: '9', name: 'Rushing Yards', category: 'Rushing' },
  { id: '10', name: 'Rushing TDs', category: 'Rushing' },
  { id: '11', name: 'Receptions', category: 'Receiving' },
  { id: '12', name: 'Receiving Yards', category: 'Receiving' },
  { id: '13', name: 'Receiving TDs', category: 'Receiving' },
  { id: '18', name: 'Fumbles Lost', category: 'Misc' },
  
  // Defense stats
  { id: '32', name: 'Sacks', category: 'Defense' },
  { id: '33', name: 'Interceptions', category: 'Defense' },
  { id: '34', name: 'Fumble Recoveries', category: 'Defense' },
  { id: '35', name: 'Defensive TDs', category: 'Defense' },
  { id: '36', name: 'Safeties', category: 'Defense' },
  { id: '37', name: 'Blocked Kicks', category: 'Defense' },
  { id: '38', name: 'Points Allowed', category: 'Defense' },
  
  // Kicker stats
  { id: '19', name: 'FG Made 0-19', category: 'Kicking' },
  { id: '20', name: 'FG Made 20-29', category: 'Kicking' },
  { id: '21', name: 'FG Made 30-39', category: 'Kicking' },
  { id: '22', name: 'FG Made 40-49', category: 'Kicking' },
  { id: '23', name: 'FG Made 50+', category: 'Kicking' },
  { id: 'fg_total', name: 'Total FG Made', category: 'Kicking' },
  { id: '29', name: 'PAT Made', category: 'Kicking' },
  { id: '30', name: 'PAT Missed', category: 'Kicking' },
  { id: 'FG_TOTAL_MISSED', name: 'Total Field Goals Missed', category: 'Kicking' },
];

const operators = [
  { value: '+', label: 'Add (+)' },
  { value: '-', label: 'Subtract (-)' },
  { value: '*', label: 'Multiply (×)' },
  { value: '/', label: 'Divide (÷)' }
];

const basePositions = ['QB', 'RB', 'WR', 'TE', 'K', 'DEF'];

const getAvailablePositions = () => {
  const positions: { value: string; label: string }[] = [];
  basePositions.forEach(pos => {
    if (pos === 'QB') {
      positions.push({ 
        value: 'QB1', 
        label: 'QB1' 
      });
    } else if (pos === 'TE') {
      for (let i = 1; i <= 2; i++) {
        positions.push({ 
          value: `TE${i}`, 
          label: `TE${i}`
        });
      }
    } else {
      for (let i = 1; i <= 3; i++) {
        positions.push({ 
          value: `${pos}${i}`, 
          label: `${pos}${i}`
        });
      }
    }
  });

  // Add K and DEF positions with explicit numbers
  positions.push({ value: 'K1', label: 'K' });
  positions.push({ value: 'DEF1', label: 'DEF' });

  return positions;
};

const ChallengeCalculator: React.FC<ChallengeCalculatorProps> = ({ 
  challengeId,
  onSaveSuccess 
}) => {
  // State management
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedWeek, setSelectedWeek] = useState<number>(0);
  const [stakes, setStakes] = useState<Stake[]>([{ title: '', description: '' }]);
  const [formulaGroups, setFormulaGroups] = useState<FormulaGroup[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [currentNflWeek, setCurrentNflWeek] = useState<number>(1);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
const [imagePreview, setImagePreview] = useState<string | null>(null);
const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fetchExistingChallenge = async () => {
      if (!challengeId) return;

      try {
        const challengeDoc = await getDoc(doc(db, 'customChallenges', challengeId));
        if (challengeDoc.exists()) {
          const data = challengeDoc.data();
          setTitle(data.title || '');
          setSubtitle(data.subtitle || '');
          setDescription(data.description || '');
          setSelectedWeek(data.week || 0);
          setStakes(data.stakes || [{ title: '', description: '' }]);
          setFormulaGroups(data.formulaGroups || []);
        }
      } catch (error) {
        console.error('Error fetching challenge:', error);
        setError('Failed to load challenge data');
      }
    };

    const fetchCurrentWeek = async () => {
      try {
        const weekDoc = await getDoc(doc(db, 'currentWeek', 'nfl'));
        if (weekDoc.exists()) {
          setCurrentNflWeek(weekDoc.data().week || 1);
        }
      } catch (error) {
        console.error('Error fetching current week:', error);
      }
    };

    fetchCurrentWeek();
    if (challengeId) {
      fetchExistingChallenge();
    }
  }, [challengeId]);

  // Formula Group Management
  const handleAddFormulaGroup = () => {
    setFormulaGroups([
      ...formulaGroups,
      {
        name: `Formula ${formulaGroups.length + 1}`,
        description: '',
        steps: []
      }
    ]);
  };

  const handleAddFormulaStep = (groupIndex: number, type: FormulaStep['type']) => {
    const newGroups = [...formulaGroups];
    const newStep: FormulaStep = type === 'number' 
      ? { type: 'number', value: 0 }
      : type === 'operator'
      ? { type: 'operator', value: '+' }
      : { 
          type: 'stat', 
          value: '', 
          playerStat: { position: '', positionRank: 1, statId: '' } 
        };
    
    newGroups[groupIndex].steps.push(newStep);
    setFormulaGroups(newGroups);
  };

  const handleRemoveFormulaStep = (groupIndex: number, stepIndex: number) => {
    const newGroups = [...formulaGroups];
    newGroups[groupIndex].steps.splice(stepIndex, 1);
    setFormulaGroups(newGroups);
  };

  const handleUpdateFormulaStep = (
    groupIndex: number,
    stepIndex: number,
    field: string,
    value: string | number
  ) => {
    const newGroups = [...formulaGroups];
    const step = newGroups[groupIndex].steps[stepIndex];
  
    if (step.type === 'stat' && step.playerStat) {
      if (field === 'position') {
        // Special handling for K and DEF
        if (value === 'K' || value === 'DEF') {
          step.playerStat.position = value;
          step.playerStat.positionRank = 1; // Default to 1 for these positions
        } else {
          // Original logic for other positions
          const [basePos, rank] = (value as string).match(/([A-Z]+)(\d*)/)?.slice(1) || ['', ''];
          step.playerStat.position = basePos;
          step.playerStat.positionRank = parseInt(rank) || 1;
        }
      } else if (field === 'statId') {
        step.playerStat.statId = value as string;
      }
    } else {
      step.value = value;
    }
  
    setFormulaGroups(newGroups);
  };

  // Stakes Management
  const handleAddStake = () => {
    setStakes([...stakes, { title: '', description: '' }]);
  };

  const handleRemoveStake = (index: number) => {
    setStakes(stakes.filter((_, i) => i !== index));
  };

  const handleStakeChange = (index: number, field: keyof Stake, value: string) => {
    const newStakes = [...stakes];
    newStakes[index][field] = value;
    setStakes(newStakes);
  };

  // Validation
  const validateChallenge = () => {
    if (!title.trim()) {
      setError('Title is required');
      return false;
    }
    
    if (!description.trim()) {
      setError('Description is required');
      return false;
    }

    if (selectedWeek === 0) {
      setError('Please select a week');
      return false;
    }

    if (stakes.length === 0 || !stakes.some(stake => stake.title.trim() && stake.description.trim())) {
      setError('At least one complete stake is required');
      return false;
    }

    if (formulaGroups.length === 0) {
      setError('At least one formula is required');
      return false;
    }

    // Validate formula groups
    for (const group of formulaGroups) {
      if (!group.name.trim()) {
        setError('All formulas must have a name');
        return false;
      }

      if (group.steps.length === 0) {
        setError(`Formula "${group.name}" has no steps`);
        return false;
      }

      // Check for invalid step sequences
      let lastStepType = '';
      for (const step of group.steps) {
        if (step.type === 'operator' && lastStepType === 'operator') {
          setError(`Formula "${group.name}" has consecutive operators`);
          return false;
        }
        if (step.type === 'stat' && !step.playerStat?.statId) {
          setError(`Formula "${group.name}" has incomplete stat selection`);
          return false;
        }
        lastStepType = step.type;
      }

      // Check first and last steps aren't operators
      if (group.steps[0].type === 'operator' || 
          group.steps[group.steps.length - 1].type === 'operator') {
        setError(`Formula "${group.name}" cannot start or end with an operator`);
        return false;
      }
    }

    setError(null);
    return true;
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
    }
  };
  
  const handleRemoveImage = () => {
    setSelectedImage(null);
    setImagePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  // Save functionality
  const handleSave = async () => {
    if (!validateChallenge()) {
      return;
    }
  
    setIsSaving(true);
    try {
      // Upload image and get URL if image exists
      let imageUrl = null;
      if (selectedImage) {
        const storageRef = ref(storage, `custom-challenges/${Date.now()}_${selectedImage.name}`);
        await uploadBytes(storageRef, selectedImage);
        imageUrl = await getDownloadURL(storageRef);
      }
  
      const challengeData = {
        title: title.trim(),
        subtitle: subtitle.trim(),
        description: description.trim(),
        week: selectedWeek,
        stakes: stakes.filter(stake => stake.title.trim() && stake.description.trim()),
        formulaGroups: formulaGroups.map(group => ({
          ...group,
          name: group.name.trim(),
          description: group.description.trim(),
          steps: group.steps.map(step => ({
            ...step,
            value: step.type === 'number' ? Number(step.value) : step.value
          }))
        })),
        image: imageUrl, // This ensures the image URL is properly saved
        status: 'active' as const,
        updatedAt: serverTimestamp()
      };
  
      if (challengeId) {
        await updateDoc(doc(db, 'customChallenges', challengeId), challengeData);
      } else {
        await addDoc(collection(db, 'customChallenges'), {
          ...challengeData,
          createdAt: serverTimestamp()
        });
      }
  
      // Clear form and image state after successful save
      if (!challengeId) {
        setTitle('');
        setSubtitle('');
        setDescription('');
        setSelectedWeek(0);
        setStakes([{ title: '', description: '' }]);
        setFormulaGroups([]);
        setSelectedImage(null); // Clear selected image
        setImagePreview(null); // Clear image preview
        if (fileInputRef.current) {
          fileInputRef.current.value = ''; // Reset file input
        }
      }
  
      if (onSaveSuccess) {
        onSaveSuccess();
      }
      
      setError(null);
    } catch (error) {
      console.error('Error saving challenge:', error);
      setError('Failed to save challenge. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const groupedStats = availableStats.reduce((acc, stat) => {
    if (!acc[stat.category]) {
      acc[stat.category] = [];
    }
    acc[stat.category].push(stat);
    return acc;
  }, {} as Record<string, typeof availableStats>);

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-8">
      {/* Challenge Info Section */}
      <div className="bg-white rounded-lg shadow p-6 space-y-4">
        <h2 className="text-2xl font-bold mb-4">Challenge Information</h2>
        
        {error && (
          <div className="flex items-center gap-2 bg-red-50 border-l-4 border-red-500 p-4 mb-4">
            <AlertTriangle className="text-red-500" />
            <p className="text-red-700">{error}</p>
          </div>
        )}

        <div className="grid gap-4 md:grid-cols-2">
          <div>
            <label className="block text-sm font-medium mb-2">Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#18f7b1]"
              placeholder="Enter challenge title"
            />
          </div>

          <div>
  <label className="block text-sm font-medium mb-2">Challenge Image</label>
  <div className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md">
    <div className="space-y-2 text-center">
      <div className="flex flex-col items-center">
        {imagePreview ? (
          <div className="relative">
            <img
              src={imagePreview}
              alt="Challenge preview"
              className="max-h-48 rounded-lg"
            />
            <button
              type="button"
              onClick={handleRemoveImage}
              className="absolute -top-2 -right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
            >
              <X size={16} />
            </button>
          </div>
        ) : (
          <Camera size={48} className="mx-auto text-gray-400" />
        )}
      </div>
      
      <div className="flex text-sm">
        <label
          htmlFor="image-upload"
          className="relative cursor-pointer rounded-md font-medium text-[#18f7b1] hover:text-[#0fd494]"
        >
          <span>{imagePreview ? 'Change image' : 'Upload an image'}</span>
          <input
            id="image-upload"
            ref={fileInputRef}
            type="file"
            className="sr-only"
            accept="image/*"
            onChange={handleImageChange}
          />
        </label>
      </div>
      <p className="text-sm text-gray-500">
        PNG, JPG, GIF up to 10MB
      </p>
    </div>
  </div>
</div>

          <div>
            <label className="block text-sm font-medium mb-2">Week</label>
            <select
              value={selectedWeek}
              onChange={(e) => setSelectedWeek(Number(e.target.value))}
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#18f7b1]"
            >
              <option value={0}>Select Week</option>
              {Array.from({ length: 18 }, (_, i) => {
                const weekNum = i + 1;
                let status = weekNum < currentNflWeek ? 'completed' 
                  : weekNum === currentNflWeek ? 'current' 
                  : 'upcoming';
                return (
                  <option 
                    key={weekNum} 
                    value={weekNum}
                    className={
                      status === 'current' 
                        ? 'font-bold' 
                        : status === 'completed' 
                          ? 'text-gray-500' 
                          : ''
                    }
                  >
                    Week {weekNum} {status === 'current' ? '(Current)' : ''}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">Subtitle</label>
          <input
            type="text"
            value={subtitle}
            onChange={(e) => setSubtitle(e.target.value)}
            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#18f7b1]"
            placeholder="Optional subtitle for your challenge"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#18f7b1]"
            rows={4}
            placeholder="Describe how the challenge works and what players need to do"
          />
        </div>
      </div>

      {/* Stakes Section */}
      <div className="bg-white rounded-lg shadow p-6 space-y-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Stakes</h2>
          <button
            onClick={handleAddStake}
            className="p-2 bg-[#18f7b1] text-black rounded-full hover:bg-[#0fd494] transition-colors"
            title="Add new stake"
          >
            <Plus size={20} />
          </button>
        </div>

        {stakes.map((stake, index) => (
          <div key={index} className="p-4 border rounded-lg space-y-3">
            <div className="flex gap-4">
              <div className="flex-grow space-y-3">
                <input
                  type="text"
                  value={stake.title}
                  onChange={(e) => handleStakeChange(index, 'title', e.target.value)}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#18f7b1]"
                  placeholder="Stake title (e.g., 'Winner's Prize')"
                />
                <textarea
                  value={stake.description}
                  onChange={(e) => handleStakeChange(index, 'description', e.target.value)}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#18f7b1]"
                  rows={2}
                  placeholder="Describe what happens to the winner/loser"
                />
              </div>
              {stakes.length > 1 && (
                <button
                  onClick={() => handleRemoveStake(index)}
                  className="text-red-500 hover:text-red-700 p-2"
                  title="Remove stake"
                >
                  <X size={20} />
                </button>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Formula Builder Section */}
      <div className="bg-white rounded-lg shadow p-6 space-y-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Formula Builder</h2>
          <button
            onClick={handleAddFormulaGroup}
            className="p-2 bg-[#18f7b1] text-black rounded-full hover:bg-[#0fd494] transition-colors"
            title="Add new formula"
          >
            <Calculator size={20} />
          </button>
        </div>

        {formulaGroups.map((group, groupIndex) => (
          <div key={groupIndex} className="p-4 border rounded-lg space-y-4">
            <div className="flex items-center justify-between">
              <input
                type="text"
                value={group.name}
                onChange={(e) => {
                  const newGroups = [...formulaGroups];
                  newGroups[groupIndex].name = e.target.value;
                  setFormulaGroups(newGroups);
                }}
                className="text-xl font-bold bg-transparent border-b-2 border-transparent focus:border-[#18f7b1] focus:outline-none"
                placeholder="Formula Name"
              />
              
              <div className="flex gap-2">
                <button
                  onClick={() => handleAddFormulaStep(groupIndex, 'stat')}
                  className="px-3 py-1 bg-blue-500 text-white rounded-md text-sm hover:bg-blue-600"
                  title="Add player stat"
                >
                  + Stat
                </button>
                <button
                  onClick={() => handleAddFormulaStep(groupIndex, 'operator')}
                  className="px-3 py-1 bg-green-500 text-white rounded-md text-sm hover:bg-green-600"
                  title="Add operator"
                >
                  + Operator
                </button>
                <button
                  onClick={() => handleAddFormulaStep(groupIndex, 'number')}
                  className="px-3 py-1 bg-purple-500 text-white rounded-md text-sm hover:bg-purple-600"
                  title="Add number"
                >
                  + Number
                </button>
                <button
                  onClick={() => {
                    const newGroups = [...formulaGroups];
                    newGroups.splice(groupIndex, 1);
                    setFormulaGroups(newGroups);
                  }}
                  className="p-1 text-red-500 hover:text-red-700 rounded-full"
                  title="Remove formula"
                >
                  <X size={20} />
                </button>
              </div>
            </div>

            <div className="flex flex-wrap gap-2 items-center min-h-[50px] p-2 bg-gray-50 rounded-lg">
              {group.steps.map((step, stepIndex) => (
                <div key={stepIndex} className="flex items-center gap-2 bg-white p-2 rounded-md shadow-sm">
                 {step.type === 'stat' ? (
  <div className="flex gap-2">
    <select
      value={`${step.playerStat?.position}${step.playerStat?.positionRank}`}
      onChange={(e) => handleUpdateFormulaStep(groupIndex, stepIndex, 'position', e.target.value)}
      className="p-2 border rounded-md bg-white"
    >
      <option value="">Position</option>
      {getAvailablePositions().map(pos => (
        <option key={pos.value} value={pos.value}>{pos.label}</option>
      ))}
    </select>
    
    <select
      value={step.playerStat?.statId || ''}
      onChange={(e) => handleUpdateFormulaStep(groupIndex, stepIndex, 'statId', e.target.value)}
      className="p-2 border rounded-md bg-white"
    >
      <option value="">Select Stat</option>
      {availableStats.map(stat => (
        <option key={stat.id} value={stat.id}>
          {stat.name}
        </option>
      ))}
    </select>
  </div>
                  ) : step.type === 'operator' ? (
                    <select
                      value={step.value}
                      onChange={(e) => handleUpdateFormulaStep(groupIndex, stepIndex, 'value', e.target.value)}
                      className="p-2 border rounded-md w-32 bg-white"
                    >
                      {operators.map(op => (
                        <option key={op.value} value={op.value}>{op.label}</option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type="number"
                      value={step.value}
                      onChange={(e) => handleUpdateFormulaStep(groupIndex, stepIndex, 'value', parseFloat(e.target.value))}
                      className="p-2 border rounded-md w-32"
                      placeholder="Enter number"
                    />
                  )}
                  
                  <button
                    onClick={() => handleRemoveFormulaStep(groupIndex, stepIndex)}
                    className="text-red-500 hover:text-red-700"
                    title="Remove step"
                  >
                    <X size={16} />
                  </button>
                </div>
              ))}
              {group.steps.length === 0 && (
                <div className="text-gray-400 italic">
                  Add steps to build your formula
                </div>
              )}
            </div>
          </div>
        ))}

        {formulaGroups.length === 0 && (
          <div className="text-center text-gray-500 py-8">
            Click the calculator icon above to add your first formula
          </div>
        )}
      </div>

      {/* Save Button */}
      <div className="flex justify-end">
        <button
          onClick={handleSave}
          disabled={isSaving}
          className={`
            px-6 py-3 bg-[#18f7b1] text-black rounded-lg hover:bg-[#0fd494] 
            flex items-center gap-2 transition-all duration-200
            ${isSaving ? 'opacity-50 cursor-not-allowed' : ''}
          `}
        >
          {isSaving ? (
            <>
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-black"></div>
              Saving...
            </>
          ) : (
            <>
              <Save size={20} />
              {challengeId ? 'Update Challenge' : 'Save Challenge'}
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default ChallengeCalculator;