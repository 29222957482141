import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const SignupPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userType, setUserType] = useState('commissioner');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [TypeformWidget, setTypeformWidget] = useState<any>(null);
  const navigate = useNavigate();
  const { signup, getDashboardRoute } = useAuth();

  // Dynamically import Typeform Widget
  useEffect(() => {
    const loadTypeform = async () => {
      try {
        const { Widget } = await import('@typeform/embed-react');
        setTypeformWidget(() => Widget);
      } catch (error) {
        console.error('Error loading Typeform:', error);
      }
    };
    loadTypeform();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    if (password !== confirmPassword) {
      setError("Passwords don't match");
      setIsLoading(false);
      return;
    }

    if (password.length < 6) {
      setError("Password should be at least 6 characters long");
      setIsLoading(false);
      return;
    }

    try {
      await signup(email, password, userType);
      navigate(getDashboardRoute());
    } catch (error) {
      console.error('Signup error:', error);
      setError('Failed to create an account. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar />
      <div className="mx-auto bg-white p-8 border border-gray-300 rounded-lg shadow-lg">
        <h2 className="text-6xl font-antonio font-bold mb-6 text-center">
          Join Our Early Access Commissioner Community
        </h2>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        
        {/* Typeform Section */}
        <section className="container mx-auto px-4 mb-12 mt-20">
          <div className="w-full mx-auto bg-[#444444] p-6 rounded-lg shadow-lg h-[40rem]">
            {TypeformWidget && (
              <TypeformWidget id="aUOuhTAX" className="w-full h-full" />
            )}
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default SignupPage;