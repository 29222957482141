import { getAnalytics, logEvent, setAnalyticsCollectionEnabled, isSupported } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './firebaseConfig'

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics with a check for support
let analytics: any = null;

const initAnalytics = async () => {
  try {
    // Log the current environment and domain
    console.log('Current environment:', process.env.NODE_ENV);
    console.log('Current domain:', window.location.hostname);
    console.log('Firebase config being used:', {
      ...firebaseConfig,
      apiKey: firebaseConfig.apiKey ? '[PRESENT]' : '[MISSING]',
    });

    if (await isSupported()) {
      analytics = getAnalytics(app);
      // Enable analytics data collection
      setAnalyticsCollectionEnabled(analytics, true);
      
      // Test event to verify initialization
      logEvent(analytics, 'app_init', {
        environment: process.env.NODE_ENV,
        domain: window.location.hostname
      });
      
      console.log('Firebase Analytics initialized successfully');
      return true;
    } else {
      console.warn('Firebase Analytics is not supported in this environment');
    }
  } catch (error) {
    console.error('Firebase Analytics initialization error:', error);
    console.error('Error details:', {
      message: error instanceof Error ? error.message : 'Unknown error',
      stack: error instanceof Error ? error.stack : undefined
    });
  }
  return false;
};

export const trackPageView = async (path: string) => {
  if (!analytics) {
    const initialized = await initAnalytics();
    console.log('Analytics initialized on page view:', initialized);
  }
  
  if (analytics) {
    try {
      logEvent(analytics, 'page_view', {
        page_path: path,
        page_location: window.location.href,
        page_title: document.title
      });
      console.log('Page view tracked successfully:', path);
    } catch (error) {
      console.error('Error tracking page view:', error);
    }
  } else {
    console.warn('Analytics not available for tracking page view');
  }
};

export const trackEvent = async (eventName: string, eventParams?: Record<string, any>) => {
  if (!analytics) {
    const initialized = await initAnalytics();
    console.log('Analytics initialized on event:', initialized);
  }

  if (analytics) {
    try {
      logEvent(analytics, eventName, eventParams);
      console.log('Event tracked successfully:', eventName, eventParams);
    } catch (error) {
      console.error('Error tracking event:', error);
    }
  } else {
    console.warn('Analytics not available for tracking event');
  }
};