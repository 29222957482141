import { Helmet } from "react-helmet";

interface MetaHeadProps {
  title: string;
  description: string;
  image?: string;
  url?: string;
  type?: string;
  keywords?: string[];
}

// Default SEO keywords for fantasy football punishments
const defaultKeywords = [
  'fantasy football punishments',
  'best fantasy football punishments',
  'fantasy football loser',
  'fantasy punishments',
  'punishments for fantasy football',
  'fantasy punishment',
  'worst fantasy football punishments',
  'good fantasy football punishments',
  'funny fantasy football punishments',
  'fantasy football loser punishments',
  'last place fantasy football punishments',
  'best fantasy punishments 2024',
  'punishment ideas',
  'fantasy loser punishments',
  'last place fantasy football',
  'loser of fantasy football',
  'fantasy football punishment ideas',
  'fantasy football commissioner tools',
  'fantasy football league management',
  'weekly fantasy challenges'
];

const MetaHead = ({ 
  title, 
  description, 
  image = "/api/placeholder/1200/630", // Default social image
  url = "https://fantasyloser.com", // Your actual domain
  type = "website",
  keywords = defaultKeywords
}: MetaHeadProps) => {
  const fullTitle = `${title} | Fantasy Loser`;
  
  return (
    <Helmet>
      {/* Standard Meta Tags */}
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(', ')} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {/* Additional Tags */}
      <meta name="robots" content="index,follow" />
      <link rel="canonical" href={url} />
    </Helmet>
  );
};

// Example home page meta data
export const homePageMeta = {
  title: "Fantasy Football Platform",
  description: "Fantasy Loser is your ultimate platform for fantasy football punishment ideas, weekly challenges, and league management tools. From hilarious last-place punishments to engaging weekly challenges, make your fantasy football league more exciting. Join our community of commissioners and discover the perfect way to punish your league's losers.",
  keywords: [
    ...defaultKeywords,
    'fantasy football league ideas',
    'fantasy football engagement',
    'commissioner tools',
    'league management platform',
    'fantasy football community',
    'fantasy football weekly challenges',
    'last place punishment ideas',
    'fantasy football league fun',
    'fantasy sports platform',
    'fantasy football commissioner'
  ]
};

export default MetaHead;