import { doc, setDoc, getDoc, deleteDoc } from 'firebase/firestore';
import { db } from './firebase';
import { nanoid } from 'nanoid';
import { sendVerificationEmail } from './sendgrid-service';

interface VerificationAttempt {
  code: string;
  email: string;
  leagueId: string;
  teamId: string;
  teamName: string;
  leagueName: string; // Added this field
  createdAt: number;
  attempts: number;
}

const VERIFICATION_CODE_LENGTH = 6;
const VERIFICATION_EXPIRY = 15 * 60 * 1000; // 15 minutes
const MAX_VERIFICATION_ATTEMPTS = 3;

export const generateVerificationCode = () => {
  // Generate a 6-digit numeric code
  return Math.floor(100000 + Math.random() * 900000).toString();
};

export const createVerificationAttempt = async (
  email: string,
  leagueId: string,
  teamId: string,
  teamName: string,
  leagueName: string // Added this parameter
): Promise<{ verificationId: string; code: string }> => {
  try {
    const verificationId = nanoid();
    const code = generateVerificationCode();
    
    const verificationRef = doc(db, 'verifications', verificationId);
    const attempt: VerificationAttempt = {
      code,
      email,
      leagueId,
      teamId,
      teamName,
      leagueName, // Added this field
      createdAt: Date.now(),
      attempts: 0
    };

    // Create verification record first
    await setDoc(verificationRef, attempt);
    
    // Send verification email with all required parameters
    const emailSent = await sendVerificationEmail(
      email,
      code,
      teamName,
      leagueName
    );
    
    if (!emailSent) {
      // If email fails, clean up verification record
      await deleteDoc(verificationRef);
      throw new Error('Failed to send verification email');
    }

    return { verificationId, code };
  } catch (error) {
    console.error('Error creating verification attempt:', error);
    throw error;
  }
};

export const verifyCode = async (
  verificationId: string,
  submittedCode: string
): Promise<{ 
  isValid: boolean; 
  error?: string;
  verificationData?: Omit<VerificationAttempt, 'code'> 
}> => {
  const verificationRef = doc(db, 'verifications', verificationId);
  const verificationDoc = await getDoc(verificationRef);

  if (!verificationDoc.exists()) {
    return { isValid: false, error: 'Verification not found' };
  }

  const verification = verificationDoc.data() as VerificationAttempt;
  const now = Date.now();

  // Check expiration
  if (now - verification.createdAt > VERIFICATION_EXPIRY) {
    await deleteDoc(verificationRef);
    return { isValid: false, error: 'Verification code has expired' };
  }

  // Check attempts
  if (verification.attempts >= MAX_VERIFICATION_ATTEMPTS) {
    await deleteDoc(verificationRef);
    return { isValid: false, error: 'Too many attempts. Please request a new code.' };
  }

  // Update attempts
  await setDoc(verificationRef, {
    ...verification,
    attempts: verification.attempts + 1
  }, { merge: true });

  if (verification.code !== submittedCode) {
    return { 
      isValid: false, 
      error: `Invalid code. ${MAX_VERIFICATION_ATTEMPTS - verification.attempts - 1} attempts remaining.`
    };
  }

  // Clean up the verification document
  await deleteDoc(verificationRef);

  // Return the verification data without the code
  const { code, ...verificationData } = verification;
  return { 
    isValid: true,
    verificationData
  };
};