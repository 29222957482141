import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useYahooAuth } from '../contexts/YahooAuthContext';
import axios from 'axios';

const YahooCallback: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { updateYahooAuthState } = useYahooAuth();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleCallback = async () => {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get('code');
      const state = searchParams.get('state');
      const errorParam = searchParams.get('error');
      const errorDescription = searchParams.get('error_description');

      if (errorParam) {
        console.error('Yahoo Auth Error:', {
          error: errorParam,
          description: errorDescription,
          rawUrl: window.location.href
        });
        
        let errorMessage = 'Authentication failed';
        if (errorParam === 'invalid_scope') {
          errorMessage = 'Application permissions are not properly configured. Please contact support.';
        }
        
        setError(errorMessage);
        return;
      }

      if (!code || !state) {
        console.error('Missing code or state');
        setError('Invalid authentication response');
        return;
      }

      const savedState = localStorage.getItem('yahoo_oauth_state');
      if (state !== savedState) {
        console.error('State mismatch:', { received: state, saved: savedState });
        setError('Security verification failed');
        return;
      }

      try {
        const response = await axios.post('/.netlify/functions/yahoo-token', { 
          code,
          redirectUri: `${window.location.origin}/yahoo-callback` // Add this
        });

        const { access_token, refresh_token, expires_in, token_type } = response.data;
        
        await updateYahooAuthState(access_token, refresh_token, expires_in, token_type);
        
        const redirectPath = localStorage.getItem('yahoo_auth_redirect') || '/dashboard';
        localStorage.removeItem('yahoo_auth_redirect');
        localStorage.removeItem('yahoo_oauth_state');
        
        navigate(redirectPath);
      } catch (error) {
        console.error('Token exchange error:', error);
        if (axios.isAxiosError(error)) {
          setError(`Authentication failed: ${error.response?.data?.error || error.message}`);
        } else {
          setError('An unexpected error occurred');
        }
      }
    };

    handleCallback();
  }, [navigate, location, updateYahooAuthState]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="max-w-md w-full space-y-8 p-6 bg-white rounded-lg shadow-lg">
          <div className="text-center">
            <h2 className="text-xl font-bold text-red-600">Authentication Error</h2>
            <p className="mt-2 text-gray-600">{error}</p>
            <div className="mt-4 space-y-2">
              <button
                onClick={() => navigate('/')}
                className="px-4 py-2 bg-[#18f7b1] text-black rounded hover:bg-[#0fd494]"
              >
                Return Home
              </button>
              <button
                onClick={() => window.location.reload()}
                className="ml-2 px-4 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
              >
                Try Again
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="text-center">
        <h2 className="text-xl font-bold mb-4">Processing Yahoo Authentication...</h2>
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#18f7b1] mx-auto"></div>
      </div>
    </div>
  );
};

export default YahooCallback;