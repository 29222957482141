import { useState } from 'react';

interface Option {
  value: string;
  label: string[];
}

interface CustomSelectProps {
  value: string | undefined;  // Updated to allow undefined
  onChange: (value: string) => void;
  options: Option[];
  placeholder?: string;
  name: string;
}

export default function CustomSelect({ 
  value = '', // Provide default value
  onChange, 
  options, 
  placeholder = 'Select an option', 
  name 
}: CustomSelectProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (optionValue: string) => {
    onChange(optionValue);
    setIsOpen(false);
  };

  const selectedOption = options.find(option => option.value === value);

  return (
    <div className="relative mt-1">
      <button
        type="button"
        className="relative w-full bg-gray-50 border border-gray-300 rounded-md py-2 px-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedOption ? (
          <span className="block truncate">
            {selectedOption.label.map((line, i) => (
              <span key={i} className="block">
                {line}
              </span>
            ))}
          </span>
        ) : (
          <span className="text-gray-400">{placeholder}</span>
        )}
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base overflow-auto focus:outline-none sm:text-sm">
          {options.map((option) => (
            <div
              key={option.value}
              className={`cursor-pointer select-none relative py-2 px-3 hover:bg-[#18f7b1] hover:text-white ${
                option.value === value ? 'bg-[#18f7b1] text-white' : ''
              }`}
              onClick={() => handleSelect(option.value)}
            >
              {option.label.map((line, i) => (
                <span key={i} className="block">
                  {line}
                </span>
              ))}
            </div>
          ))}
        </div>
      )}
      
      {/* Hidden input for form submission */}
      <input 
        type="hidden" 
        name={name} 
        value={value || ''} 
      />
    </div>
  );
}