import React from 'react';

const StyledLoadingSpinner = () => {
  return (
    <div className="fixed inset-0 bg-[#1A1A1A] z-50 flex flex-col justify-center items-center">
      <div className="relative flex flex-col items-center">
        {/* Primary spinner */}
        <div className="relative">
          {/* Outer ring */}
          <div className="w-24 h-24 border-4 border-[#18f7b1]/20 border-solid rounded-full"></div>
          
          {/* Spinning inner ring */}
          <div className="absolute top-0 left-0 w-24 h-24 border-4 border-[#18f7b1] border-solid rounded-full animate-spin border-t-transparent"></div>
        </div>

        {/* Loading text */}
        <div className="mt-8 flex flex-col items-center">
          <h2 className="text-[#18f7b1] text-4xl font-antonio uppercase tracking-wider">
            Loading
          </h2>
          <div className="flex gap-1 mt-2">
            {[...Array(3)].map((_, i) => (
              <div
                key={i}
                className="w-3 h-3 bg-[#18f7b1] rounded-full animate-bounce"
                style={{
                  animationDelay: `${i * 0.2}s`,
                  animationDuration: '1s'
                }}
              ></div>
            ))}
          </div>
        </div>
      </div>

      {/* Brand text */}
      <div className="absolute bottom-8 text-white/50 font-antonio text-xl">
        FANTASY LOSER
      </div>
    </div>
  );
};

export default StyledLoadingSpinner;