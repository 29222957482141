import axios from 'axios';
import { XMLParser } from 'fast-xml-parser';
import { SeasonRecord, YahooLeague } from '../types/yahoo';

interface GameKeyMapping {
  [key: string]: string;
  current: string;
}

export class YahooSeasonRecordHandler {
  private token: string;
  private refreshToken: () => Promise<string>;
  private parser: XMLParser;
  private lastRequestTime: number = 0;
  private readonly requestDelay: number = 1000;
  private readonly maxRetries: number = 3;
  
  private readonly gameKeyMap: GameKeyMapping = {
    current: '449', // Current NFL season (2024)
    '2023': '423',
    '2022': '414',
    '2021': '406',
    '2020': '399',
    '2019': '390',
    '2018': '380',
    '2017': '371',
    '2016': '359',
    '2015': '348',
    '2014': '331',
    '2013': '314',
    '2012': '273',
    '2011': '257',
    '2010': '242',
    '2009': '222',
    '2008': '199',
    '2007': '175',
    '2006': '153',
    '2005': '124',
    '2004': '101',
    '2003': '79',
    '2002': '49',
    '2001': '57'
  };

  constructor(token: string, refreshToken: () => Promise<string>) {
    this.token = token;
    this.refreshToken = refreshToken;
    this.parser = new XMLParser({
      ignoreAttributes: false,
      attributeNamePrefix: "@_",
      parseAttributeValue: true,
      ignoreDeclaration: true
    });
  }

  private async waitForRateLimit() {
    const now = Date.now();
    const timeSinceLastRequest = now - this.lastRequestTime;
    if (timeSinceLastRequest < this.requestDelay) {
      await new Promise(resolve => setTimeout(resolve, this.requestDelay - timeSinceLastRequest));
    }
    this.lastRequestTime = Date.now();
  }

  private normalizeLeagueKey(leagueKey: string): string {
    try {
      const parts = leagueKey.split('.');
      if (parts.length >= 3) {
        const [gameKey, l, leagueId] = parts;
        
        // If it's a current season key (449), let it pass through
        if (gameKey === '449') {
          return leagueKey;
        }
        
        // For renewed_from (previous season) keys, use our mapping
        if (Object.values(this.gameKeyMap).includes(gameKey)) {
          return leagueKey;
        }
  
        // If we need to normalize, use current season key
        const normalizedGameKey = this.gameKeyMap.current;
        console.log(`Normalizing league key from ${gameKey} to ${normalizedGameKey}`);
        return `${normalizedGameKey}.l.${leagueId}`;
      }
      throw new Error(`Invalid league key format: ${leagueKey}`);
    } catch (error) {
      console.error('Error normalizing league key:', error);
      throw error;
    }
  }

  private getCurrentSeason(): string {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    return currentMonth >= 9 ? currentYear.toString() : (currentYear - 1).toString();
  }

  private async makeRequest(url: string, retryCount = 0): Promise<any> {
    await this.waitForRateLimit();

    try {
      console.log(`Making request to: ${url}`);
      if (!this.token) {
        console.log('No token available, refreshing...');
        this.token = await this.refreshToken();
      }

      const response = await axios.post('/.netlify/functions/yahoo-api-proxy', {
        url,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });

      return this.parser.parse(response.data);
    } catch (error) {
      console.error(`Request failed (attempt ${retryCount + 1}/${this.maxRetries}):`, error);
      
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401 && retryCount < this.maxRetries) {
          console.log('Token expired, refreshing...');
          this.token = await this.refreshToken();
          return this.makeRequest(url, retryCount + 1);
        }
        
        if (error.response?.status === 404) {
          console.log('Resource not found:', url);
          return null;
        }
      }
      
      throw error;
    }
  }

  public async getLeagueMetadata(leagueKey: string) {
    try {
      const normalizedKey = this.normalizeLeagueKey(leagueKey);
      const url = `https://fantasysports.yahooapis.com/fantasy/v2/league/${normalizedKey}/metadata`;
      console.log('Fetching metadata for:', url);
      
      const response = await this.makeRequest(url);
      const league = response?.fantasy_content?.league;
      
      if (!league) {
        console.log('No league data in metadata response');
        return null;
      }
  
      // Log the relevant fields
      console.log('Retrieved metadata for season:', league.season);
      console.log('Renew field:', league.renew);
      
      return league;
    } catch (error) {
      console.error(`Error fetching metadata for league ${leagueKey}:`, error);
      return null;
    }
  }

  public async getLeagueStandings(leagueKey: string) {
    try {
      const normalizedKey = this.normalizeLeagueKey(leagueKey);
      const url = `https://fantasysports.yahooapis.com/fantasy/v2/league/${normalizedKey}/standings`;
      console.log('Fetching standings for:', url);
      
      const response = await this.makeRequest(url);
      if (!response?.fantasy_content?.league) {
        console.log('No standings data found');
        return null;
      }
      return response.fantasy_content.league;
    } catch (error) {
      console.error(`Error fetching standings for league ${leagueKey}:`, error);
      return null;
    }
  }

  private createSeasonRecord(standings: any, season: string): SeasonRecord | null {
    try {
      if (!standings?.standings?.teams?.team) {
        console.log('No team data available in standings');
        return null;
      }

      const teams = standings.standings.teams.team;
      const standingsArray = Array.isArray(teams) ? teams : [teams];
      
      standingsArray.sort((a: any, b: any) => 
        parseInt(a.team_standings.rank) - parseInt(b.team_standings.rank)
      );

      const champion = standingsArray[0];
      const lastPlace = standingsArray[standingsArray.length - 1];
      const highestScorer = standingsArray.reduce((prev: any, current: any) => {
        const prevPoints = parseFloat(prev.team_points.total);
        const currentPoints = parseFloat(current.team_points.total);
        return currentPoints > prevPoints ? current : prev;
      }, standingsArray[0]);

      console.log(`Creating season record for ${season}`);
      console.log(`Champion: ${champion.name}, Last Place: ${lastPlace.name}`);

      return {
        name: champion.name,
        team_key: champion.team_key,
        rank: champion.team_standings.rank,
        wins: champion.team_standings.outcome_totals.wins,
        losses: champion.team_standings.outcome_totals.losses,
        ties: champion.team_standings.outcome_totals.ties || "0",
        points_for: champion.team_points.total,
        season,
        topThree: standingsArray.slice(0, 3).map((team: any, index: number) => ({
          name: team.name,
          rank: index + 1,
          wins: parseInt(team.team_standings.outcome_totals.wins),
          losses: parseInt(team.team_standings.outcome_totals.losses),
          points: parseFloat(team.team_points.total)
        })),
        highestScorer: {
          name: highestScorer.name,
          points: parseFloat(highestScorer.team_points.total)
        },
        lastPlace: {
          name: lastPlace.name,
          wins: parseInt(lastPlace.team_standings.outcome_totals.wins),
          losses: parseInt(lastPlace.team_standings.outcome_totals.losses),
          points: parseFloat(lastPlace.team_points.total)
        }
      };
    } catch (error) {
      console.error('Error creating season record:', error);
      return null;
    }
  }

  private parseRenewString(renewString: string): { gameKey: string; leagueId: string } | null {
    try {
      console.log('Parsing renew string:', renewString);
      const [gameKey, leagueId] = renewString.split('_');
      
      if (!gameKey || !leagueId) {
        console.error('Invalid renew string format:', renewString);
        return null;
      }
      
      const result = { gameKey, leagueId };
      console.log('Parsed renew string result:', result);
      return result;
    } catch (error) {
      console.error('Error parsing renew string:', error);
      return null;
    }
  }

  async getAllSeasonRecords(currentLeagueKey: string): Promise<SeasonRecord[]> {
    const records: SeasonRecord[] = [];
    const processedKeys = new Set<string>();
    let leagueKey = currentLeagueKey;
    let retryCount = 0;
  
    console.log('Starting season records fetch for league:', currentLeagueKey);
  
    while (leagueKey && !processedKeys.has(leagueKey) && retryCount < this.maxRetries) {
      try {
        console.log(`Processing league: ${leagueKey}`);
        processedKeys.add(leagueKey);
  
        const metadata = await this.getLeagueMetadata(leagueKey);
        if (!metadata) {
          console.log(`No metadata found for league: ${leagueKey}`);
          break;
        }
  
        const season = metadata.season;
        console.log(`Processing season ${season} with game key ${leagueKey.split('.')[0]}`);
  
        const standings = await this.getLeagueStandings(leagueKey);
        if (!standings) {
          console.log(`No standings found for league: ${leagueKey}`);
          break;
        }
  
        const seasonRecord = this.createSeasonRecord(standings, season);
        if (seasonRecord) {
          console.log(`Adding record for season ${season}`);
          records.push(seasonRecord);
  
          // Check for renew field and process previous season
          const renewString = metadata.renew;
          if (renewString) {
            console.log(`Found renew string for season ${season}:`, renewString);
            const previousSeasonKeys = this.parseRenewString(renewString);
            if (previousSeasonKeys) {
              const previousLeagueKey = `${previousSeasonKeys.gameKey}.l.${previousSeasonKeys.leagueId}`;
              console.log(`Setting up to process previous season with key: ${previousLeagueKey}`);
              leagueKey = previousLeagueKey;
              await new Promise(resolve => setTimeout(resolve, this.requestDelay));
              continue;
            }
          }
        }
  
        break; // Only break if we've processed current season and found no valid previous season
  
      } catch (error) {
        console.error('Error processing season:', error);
        console.error('Current league key:', leagueKey);
        retryCount++;
        
        if (retryCount >= this.maxRetries) {
          console.log('Max retries reached, ending chain');
          break;
        }
  
        await new Promise(resolve => setTimeout(resolve, this.requestDelay * 2));
      }
    }
  
    console.log(`Found ${records.length} season records:`, records);
    return records.sort((a, b) => parseInt(b.season) - parseInt(a.season));
  }

  async updateLeagueWithSeasonData(yahooLeague: YahooLeague): Promise<YahooLeague> {
    try {
      const currentSeasonStandings = await this.getLeagueStandings(yahooLeague.league_key);
      const currentSeasonData = this.createSeasonRecord(
        currentSeasonStandings,
        currentSeasonStandings.season
      );

      const updatedLeague = { ...yahooLeague };

      if (currentSeasonData) {
        updatedLeague.champion = currentSeasonData;
        updatedLeague.lastPlace = {
          name: currentSeasonData.lastPlace.name,
          team_key: currentSeasonData.team_key,
          rank: (yahooLeague.num_teams || 0).toString(),
          wins: currentSeasonData.lastPlace.wins.toString(),
          losses: currentSeasonData.lastPlace.losses.toString(),
          ties: "0",
          points_for: currentSeasonData.lastPlace.points.toString(),
          season: currentSeasonData.season,
          topThree: currentSeasonData.topThree,
          highestScorer: currentSeasonData.highestScorer,
          lastPlace: currentSeasonData.lastPlace
        };
      }

      const metadata = await this.getLeagueMetadata(yahooLeague.league_key);
      const previousLeagueKey = metadata?.renewed_from;

      if (previousLeagueKey) {
        try {
          const previousSeasonStandings = await this.getLeagueStandings(previousLeagueKey);
          const previousSeasonData = this.createSeasonRecord(
            previousSeasonStandings,
            previousSeasonStandings.season
          );

          if (previousSeasonData) {
            updatedLeague.previous_season = {
              season: previousSeasonData.season,
              champion: previousSeasonData,
              lastPlace: {
                name: previousSeasonData.lastPlace.name,
                team_key: previousSeasonData.team_key,
                rank: (yahooLeague.num_teams || 0).toString(),
                wins: previousSeasonData.lastPlace.wins.toString(),
                losses: previousSeasonData.lastPlace.losses.toString(),
                ties: "0",
                points_for: previousSeasonData.lastPlace.points.toString(),
                season: previousSeasonData.season,
                topThree: previousSeasonData.topThree,
                highestScorer: previousSeasonData.highestScorer,
                lastPlace: previousSeasonData.lastPlace
              },
              league_key: previousLeagueKey
            };
          }
        } catch (error) {
          console.error('Error fetching previous season data:', error);
        }
      }

      return updatedLeague;
    } catch (error) {
      console.error('Error updating league with season data:', error);
      return yahooLeague;
    }
  }
}

export const createYahooSeasonHandler = (
  token: string, 
  refreshToken: () => Promise<string>
) => {
  return new YahooSeasonRecordHandler(token, refreshToken);
};