import React, { useState, useEffect } from 'react';
import { CheckCircle2, MinusCircle } from 'lucide-react';
import { League } from '../services/firebase'; // Adjust the import path as needed

interface DeadbeatTrackerProps {
  league: League;
  isEditing: boolean;
  onLeagueChange: (updatedLeague: League) => void;
}

const DeadbeatTracker: React.FC<DeadbeatTrackerProps> = ({ league, isEditing, onLeagueChange }) => {
  const [entries, setEntries] = useState<League['deadbeatTracker']>([]);
  const [leagueDuesInput, setLeagueDuesInput] = useState<string>('100');

  useEffect(() => {
    const initializeOrUpdateEntries = () => {
      console.log('Initializing or updating entries', league);
      if (league.deadbeatTracker && league.deadbeatTracker.length > 0) {
        console.log('Using existing deadbeat tracker', league.deadbeatTracker);
        setEntries(league.deadbeatTracker);
        const initialDues = league.deadbeatTracker.find(entry => entry.balance > 0)?.balance ?? 100;
        setLeagueDuesInput(initialDues.toString());
      } else if (league.yahooLeague && league.yahooLeague.teams && league.yahooLeague.teams.length > 0) {
        console.log('Creating new entries from Yahoo teams', league.yahooLeague.teams);
        const newEntries: League['deadbeatTracker'] = league.yahooLeague.teams.map(team => ({
          team: team.name,
          status: 'DEADBEAT',
          balance: Number(leagueDuesInput),
          datePaid: ''
        }));
        setEntries(newEntries);
        onLeagueChange({ ...league, deadbeatTracker: newEntries });
      } else {
        console.log('No deadbeat tracker or Yahoo league data available');
      }
    };
  
    initializeOrUpdateEntries();
  }, [league, leagueDuesInput, onLeagueChange]);

  const handleLeagueDuesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newInput = e.target.value;
    setLeagueDuesInput(newInput);
    
    if (newInput !== '') {
      const numDues = Number(newInput);
      const updatedEntries = entries.map(entry => ({
        ...entry,
        balance: entry?.status === 'PAID' ? 0 : numDues
      }));
      setEntries(updatedEntries);
      onLeagueChange({ ...league, deadbeatTracker: updatedEntries });
    }
  };

  const handleEntryChange = (index: number, field: keyof League['deadbeatTracker'][0], value: string | number) => {
    const updatedEntries = [...entries];
    const entry = updatedEntries[index] || {};
    if (field === 'balance') {
      const numValue = value === '' ? 0 : Number(value);
      updatedEntries[index] = {
        ...entry,
        [field]: numValue,
        status: numValue === 0 ? 'PAID' : (entry.status || 'DEADBEAT')
      };
    } else if (field === 'status') {
      const currentLeagueDues = leagueDuesInput === '' ? 0 : Number(leagueDuesInput);
      updatedEntries[index] = {
        ...entry,
        [field]: value as 'PAID' | 'DEADBEAT',
        balance: value === 'PAID' ? 0 : currentLeagueDues
      };
    } else {
      updatedEntries[index] = { ...entry, [field]: value };
    }
    setEntries(updatedEntries);
    onLeagueChange({ ...league, deadbeatTracker: updatedEntries });
  };


  const addEntry = () => {
    const newEntries = [...entries, { team: '', status: 'PAID' as const, balance: 0, datePaid: '' }];
    setEntries(newEntries);
    onLeagueChange({ ...league, deadbeatTracker: newEntries });
  };

  const removeEntry = (index: number) => {
    const updatedEntries = entries.filter((_, i) => i !== index);
    setEntries(updatedEntries);
    onLeagueChange({ ...league, deadbeatTracker: updatedEntries });
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' });
  };

  return (
    <div className="bg-black p-4 rounded-lg">
      <h2 className="text-3xl font-bold mb-4 text-[#18f7b1] italic font-eau">DEADBEAT TRACKER</h2>
      <div className="mb-4">
        <label htmlFor="leagueDues" className="text-[#18f7b1] mr-2 ml-2 font-eau">League Dues:</label>
        <div className="relative inline-block">
          <span className="absolute left-2 top-1/2 transform -translate-y-1/2 text-white font-eau">$</span>
          <input
            type="text"
            id="leagueDues"
            value={leagueDuesInput}
            onChange={handleLeagueDuesChange}
            className={`pl-6 p-1 w-24 text-white rounded font-eau
              ${isEditing 
                ? 'bg-gray-800 border border-white focus:border-[#18f7b1] focus:outline-none' 
                : 'bg-transparent'
              }`}
            disabled={!isEditing}
          />
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full text-white text-sm">
          <thead>
            <tr className="border-b border-[#18f7b1]">
              <th className="py-2 px-2 text-left w-1/3">TEAM</th>
              <th className="py-2 px-2 text-center w-1/6">STATUS</th>
              <th className="py-2 px-2 text-center w-1/6">BALANCE</th>
              <th className="py-2 px-2 text-center w-1/6">PAID</th>
              {isEditing && <th className="py-2 px-2 text-center w-1/12">REMOVE</th>}
            </tr>
          </thead>
          <tbody>
            {entries.map((entry, index) => (
              <tr key={index} className="border-b border-gray-700">
                <td className="py-2 px-2">
                  {isEditing ? (
                    <input
                      type="text"
                      value={entry?.team || ''}
                      onChange={(e) => handleEntryChange(index, 'team', e.target.value)}
                      className="w-full p-1 text-black rounded"
                    />
                  ) : (
                    <div className="truncate max-w-[120px] sm:max-w-full" title={entry?.team || ''}>
                      {entry?.team || ''}
                    </div>
                  )}
                </td>
                <td className="py-2 px-2 text-center">
                  {isEditing ? (
                    <select
                      value={entry?.status || 'PAID'}
                      onChange={(e) => handleEntryChange(index, 'status', e.target.value as 'PAID' | 'DEADBEAT')}
                      className="w-full p-1 text-black rounded text-center"
                    >
                      <option value="PAID">PAID</option>
                      <option value="DEADBEAT">DEADBEAT</option>
                    </select>
                  ) : (
                    entry?.status === 'PAID' ? (
                      <div className="flex justify-center">
                        <CheckCircle2 className="text-[#18f7b1]" size={16} />
                      </div>
                    ) : (
                      entry?.status || ''
                    )
                  )}
                </td>
                <td className="py-2 px-2 text-center">
                  {isEditing ? (
                    <input
                      type="text"
                      value={(entry?.balance === 0 ? '' : entry?.balance?.toString()) ?? ''}
                      onChange={(e) => handleEntryChange(index, 'balance', e.target.value)}
                      className="w-full p-1 text-black rounded text-center"
                      disabled={entry?.status === 'PAID'}
                    />
                  ) : (
                    entry?.status === 'PAID' ? '-' : `$${(entry?.balance ?? 0).toFixed(2)}`
                  )}
                </td>
                <td className="py-2 px-2 text-center">
                  {isEditing ? (
                    <input
                      type="date"
                      value={entry?.datePaid || ''}
                      onChange={(e) => handleEntryChange(index, 'datePaid', e.target.value)}
                      className="w-full p-1 text-black rounded text-center"
                    />
                  ) : (
                    formatDate(entry?.datePaid || '')
                  )}
                </td>
                {isEditing && (
                  <td className="py-2 px-2 text-center">
                    <button
                      onClick={() => removeEntry(index)}
                      className="text-red-500 hover:text-red-700 focus:outline-none"
                    >
                      <MinusCircle size={16} />
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isEditing && (
        <button
          onClick={addEntry}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg text-sm"
        >
          Add Entry
        </button>
      )}
    </div>
  );
};

export default DeadbeatTracker;