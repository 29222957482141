import React, { useState, useEffect } from 'react';
import { AlertCircle, AlertOctagon, Check } from 'lucide-react';
import { 
  League, 
  updateYahooLeagueId, 
  fetchYahooLeagueData,
  getYahooLeagueData
} from '../../services/firebase';
import { useYahooAuth } from '../../contexts/YahooAuthContext';
import { useAuth } from '../../contexts/AuthContext';
import { YahooLeague } from '../../types/yahoo';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../services/firebase';
import StyledLoadingSpinner from '../ui/StyledLoadingSpinner';

interface LeagueSettingsProps {
  league: League;
  updateLeague: (id: string, updatedLeagueData: Partial<League>) => Promise<void>;
}

const LeagueSettings: React.FC<LeagueSettingsProps> = ({ league, updateLeague }) => {
  const { user, userRole } = useAuth();
  const { 
    yahooIsAuthenticated, 
    yahooLogin, 
    yahooLogout, 
    checkYahooAuth, 
    yahooToken,
    refreshYahooToken 
  } = useYahooAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isYahooLinking, setIsYahooLinking] = useState(false);
  const [yahooLeagues, setYahooLeagues] = useState<YahooLeague[]>([]);
  const [selectedYahooLeague, setSelectedYahooLeague] = useState<string | null>(null);
  const [yahooError, setYahooError] = useState<string | null>(null);
  const [isYahooLeagueSelectionPending, setIsYahooLeagueSelectionPending] = useState(false);
  const [isUserCommissioner, setIsUserCommissioner] = useState(false);

  useEffect(() => {
    checkCommissionerStatus();
  }, [league, user, userRole]);

  useEffect(() => {
    const fetchYahooLeagues = async () => {
      if (yahooIsAuthenticated && yahooToken && (isYahooLinking || isYahooLeagueSelectionPending)) {
        try {
          setIsLoading(true);
          const leagues = await fetchYahooLeagueData(yahooToken, refreshYahooToken);
          setYahooLeagues(leagues);
          if (leagues.length === 0) {
            setYahooError('No Yahoo leagues found for this account. Please create a Yahoo fantasy football league first.');
          } else {
            setYahooError(null);
            setIsYahooLeagueSelectionPending(true);
          }
        } catch (error) {
          console.error('Error fetching Yahoo leagues:', error);
          setYahooError('Failed to fetch Yahoo leagues. Please try again later.');
        } finally {
          setIsLoading(false);
          setIsYahooLinking(false);
        }
      }
    };

    fetchYahooLeagues();
  }, [yahooIsAuthenticated, yahooToken, refreshYahooToken, isYahooLinking, isYahooLeagueSelectionPending]);

  const checkCommissionerStatus = () => {
    if (user && league) {
      const isCommissioner = league.commissionerId === user.uid;
      setIsUserCommissioner(isCommissioner || userRole === 'admin');
    }
  };

  const handleYahooLink = async () => {
    if (!isUserCommissioner && userRole !== 'admin') {
      setError('Only commissioners and admins can link Yahoo accounts');
      return;
    }

    if (yahooIsAuthenticated) {
      try {
        await checkYahooAuth();
        if (isYahooLeagueSelectionPending) {
          setIsYahooLeagueSelectionPending(false);
        } else {
          setIsYahooLeagueSelectionPending(true);
          const leagues = await fetchYahooLeagueData(yahooToken, refreshYahooToken);
          setYahooLeagues(leagues);
          if (leagues.length === 0) {
            setYahooError('No Yahoo leagues found for this account. Please create a Yahoo fantasy football league first.');
          } else {
            setYahooError(null);
          }
        }
      } catch (error) {
        console.error('Error checking Yahoo auth:', error);
        setYahooError('Failed to verify Yahoo authentication. Please try linking your account again.');
        await yahooLogout();
        yahooLogin();
      }
    } else {
      yahooLogin();
    }
  };

  const handleYahooLogout = async () => {
    try {
      await yahooLogout();
      setSelectedYahooLeague(null);
      setYahooLeagues([]);
      
      if (league?.id && (league.yahooLeagueId || league.yahooLeague)) {
        const updatedLeague: Partial<League> = {
          yahooLeagueId: undefined,
          yahooLeague: undefined
        };
        await updateLeague(league.id, updatedLeague);
      }
      
      setSuccess('Yahoo account unlinked successfully');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error) {
      console.error('Error unlinking Yahoo account:', error);
      setError('Failed to unlink Yahoo account. Please try again.');
    }
  };

  const handleYahooLeagueSelect = async (yahooLeagueId: string) => {
    if (!league?.id) {
      setError('Unable to link Yahoo league. Local league ID is missing.');
      return;
    }

    try {
      setIsLoading(true);
      const yahooLeagueDoc = await getDoc(doc(db, 'yahooLeagues', yahooLeagueId));
      
      if (!yahooLeagueDoc.exists()) {
        throw new Error('Selected Yahoo league data not found.');
      }

      const yahooLeagueData = yahooLeagueDoc.data() as YahooLeague;
      const teams = yahooLeagueData.teams || [];

      const currentLeagueDues = league.deadbeatTracker?.find(entry => entry?.balance > 0)?.balance ?? 100;
      const deadbeatTracker = teams.map(team => ({
        team: team.name,
        status: 'DEADBEAT' as const,
        balance: currentLeagueDues,
        datePaid: ''
      }));

      const updatedLeague: Partial<League> = {
        yahooLeagueId: yahooLeagueId,
        yahooLeague: yahooLeagueData,
        name: yahooLeagueData.name,
        yearStarted: yahooLeagueData.yearStarted,
        leagueUrl: yahooLeagueData.url,
        deadbeatTracker
      };

      await updateLeague(league.id, updatedLeague);
      setIsYahooLeagueSelectionPending(false);
      setSuccess('Yahoo league linked successfully');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error) {
      console.error('Error updating league with Yahoo data:', error);
      setError(error instanceof Error ? error.message : 'Failed to update league with Yahoo data.');
    } finally {
      setIsLoading(false);
    }
  };

  const renderYahooLeagueSelection = () => {
    if (!isYahooLeagueSelectionPending) return null;

    return (
      <div className="mb-6 bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-bold mb-4">Select Yahoo League</h3>
        {yahooError && (
          <div className="flex items-center text-red-500 mb-4 p-3 bg-red-50 rounded">
            <AlertOctagon size={20} className="mr-2 flex-shrink-0" />
            <span>{yahooError}</span>
          </div>
        )}
        <select
          value={selectedYahooLeague || ''}
          onChange={(e) => handleYahooLeagueSelect(e.target.value)}
          className="w-full p-3 border rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#18f7b1]"
        >
          <option value="">Select a league</option>
          {yahooLeagues.map((league) => (
            <option key={league.league_id} value={league.league_id}>
              {league.name}
            </option>
          ))}
        </select>
      </div>
    );
  };

  if (isLoading) {
    return <StyledLoadingSpinner />;
  }

  return (
    <div className="max-w-4xl mx-auto px-4">
      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <h2 className="text-3xl font-bold mb-6 font-eau">League Settings</h2>

        {error && (
          <div className="mb-4 flex items-center bg-red-50 text-red-700 p-4 rounded">
            <AlertCircle size={20} className="mr-2 flex-shrink-0" />
            <span>{error}</span>
          </div>
        )}

        {success && (
          <div className="mb-4 flex items-center bg-green-50 text-green-700 p-4 rounded">
            <Check size={20} className="mr-2 flex-shrink-0" />
            <span>{success}</span>
          </div>
        )}

        <div className="space-y-6">
          <div className="border-b pb-6">
            <h3 className="text-xl font-semibold mb-4">Yahoo Fantasy Integration</h3>
            <p className="text-gray-600 mb-4">
              Link your Yahoo Fantasy league to automatically sync standings, rosters, and league history.
            </p>

            {renderYahooLeagueSelection()}

            <div className="flex flex-wrap gap-3">
              {yahooIsAuthenticated ? (
                <>
                  <button
                    onClick={handleYahooLink}
                    className="px-4 py-2 bg-[#18f7b1] text-black rounded-lg font-semibold hover:bg-[#0fd494] transition-colors"
                  >
                    {isYahooLeagueSelectionPending ? 'Cancel Selection' : 
                     (league?.yahooLeagueId ? 'Change Yahoo League' : 'Select Yahoo League')}
                  </button>
                  <button
                    onClick={handleYahooLogout}
                    className="px-4 py-2 bg-red-500 text-white rounded-lg font-semibold hover:bg-red-600 transition-colors"
                  >
                    Unlink Yahoo Account
                  </button>
                </>
              ) : (
                <button
                  onClick={handleYahooLink}
                  className="px-4 py-2 bg-[#18f7b1] text-black rounded-lg font-semibold hover:bg-[#0fd494] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={!(isUserCommissioner || userRole === 'admin')}
                >
                  Link Yahoo Account
                </button>
              )}
            </div>

            {!(isUserCommissioner || userRole === 'admin') && (
              <p className="mt-2 text-sm text-gray-500">
                Only league commissioners can manage Yahoo integration settings.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeagueSettings;