import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { SeasonRecord } from '../../types/yahoo';
import { League } from '../../services/firebase';
import { useYahooAuth } from '../../contexts/YahooAuthContext';
import { createYahooSeasonHandler } from '../../services/yahoo-season-records';
import { Trophy, Award, Skull, Star, Calendar } from 'lucide-react';

interface RecordBookProps {
  league: League;
  updateLeague: (id: string, updatedLeague: Partial<League>) => Promise<void>;
}

// Helper function for cached data
const processRecords = (records: SeasonRecord[]): SeasonRecord[] => {
  const currentYear = new Date().getFullYear().toString();
  return records
    .filter(record => parseInt(record.season) < parseInt(currentYear))
    .sort((a, b) => parseInt(b.season) - parseInt(a.season));
};

const setCachedData = (
  league: League,
  setRecordBook: React.Dispatch<React.SetStateAction<SeasonRecord[]>>
): boolean => {
  if (league.recordBook?.length > 0) {
    console.log('Using cached record book data');
    const historicalRecords = processRecords(league.recordBook);
    setRecordBook(historicalRecords);
    return historicalRecords.length > 0;
  }
  return false;
};

const RecordBook: React.FC<RecordBookProps> = ({ league, updateLeague }) => {
  const [recordBook, setRecordBook] = useState<SeasonRecord[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const { yahooToken, refreshYahooToken } = useYahooAuth();

  const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours

  const isCacheValid = useCallback(() => {
    if (!league.recordBookLastUpdated) return false;
    const now = Date.now();
    const hasMultipleSeasons = league.recordBook && league.recordBook.length > 1;
    return (now - league.recordBookLastUpdated < CACHE_DURATION) && hasMultipleSeasons;
  }, [league]);

  const debouncedFetch = useCallback(
    debounce(async () => {
      if (isUpdating || !league?.id) return;
      setIsUpdating(true);
      
      try {
        if (isCacheValid()) {
          console.log('Using valid cached record book data');
          setCachedData(league, setRecordBook);
          setIsLoading(false);
          setIsUpdating(false);
          return;
        }
  
        if (!league.yahooLeague?.league_key || !yahooToken) {
          if (setCachedData(league, setRecordBook)) {
            setError('Yahoo league not linked. Showing cached data.');
          } else {
            throw new Error('Yahoo league not linked. Please connect your Yahoo account to see historical records.');
          }
          setIsLoading(false);
          setIsUpdating(false);
          return;
        }
  
        const seasonHandler = createYahooSeasonHandler(yahooToken, refreshYahooToken);
        const records = await seasonHandler.getAllSeasonRecords(league.yahooLeague.league_key);
  
        if (records.length > 0) {
          const historicalRecords = processRecords(records);
          
          if (historicalRecords.length > 0) {
            setRecordBook(historicalRecords);
            setError(null);
            await updateLeague(league.id, { 
              recordBook: records, // Still save all records including current year
              recordBookLastUpdated: Date.now()
            });
          } else {
            setError('No historical records found for this league.');
          }
        } else {
          if (!setCachedData(league, setRecordBook)) {
            setError('No historical records found for this league.');
          }
        }
      } catch (err) {
        if (!setCachedData(league, setRecordBook)) {
          setError(err instanceof Error ? err.message : 'Failed to fetch record book data');
        } else {
          setError('Failed to fetch new data. Showing cached records.');
        }
      } finally {
        setIsLoading(false);
        setIsUpdating(false);
      }
    }, 1000),
    [league, yahooToken, refreshYahooToken, updateLeague, isCacheValid]
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!isUpdating && league?.yahooLeague?.league_key) {
        await debouncedFetch();
      }
    };

    fetchData();
    return () => {
      debouncedFetch.cancel();
    };
  }, [league?.yahooLeague?.league_key, debouncedFetch]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#18f7b1]"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8 max-w-[95%] mx-auto">
      <div className="text-center">
        <h1 className="text-5xl font-bold mb-4 font-eau italic">League Record Book</h1>
        <p className="text-gray-600 mb-8">Historical seasons and achievements</p>
      </div>

      {error && (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
          <p className="text-yellow-700">{error}</p>
        </div>
      )}

      {recordBook.length === 0 ? (
        <div className="text-center py-12">
          <Calendar className="w-16 h-16 mx-auto mb-4 text-gray-400" />
          <p className="text-xl text-gray-500">No historical records available yet.</p>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white rounded-lg overflow-hidden shadow">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-4 text-left text-sm font-bold text-gray-600">Season</th>
                <th className="px-6 py-4 text-left text-sm font-bold text-gray-600">
                  <div className="flex items-center">
                    <Trophy className="w-4 h-4 mr-2 text-[#18f7b1]" />
                    Champion
                  </div>
                </th>
                <th className="px-6 py-4 text-left text-sm font-bold text-gray-600">
                  <div className="flex items-center">
                    <Award className="w-4 h-4 mr-2 text-gray-400" />
                    Runner Up
                  </div>
                </th>
                <th className="px-6 py-4 text-left text-sm font-bold text-gray-600">
                  <div className="flex items-center">
                    <Award className="w-4 h-4 mr-2 text-amber-600" />
                    Third Place
                  </div>
                </th>
                <th className="px-6 py-4 text-left text-sm font-bold text-gray-600">
                  <div className="flex items-center">
                    <Star className="w-4 h-4 mr-2 text-yellow-400" />
                    Most Points
                  </div>
                </th>
                <th className="px-6 py-4 text-left text-sm font-bold text-gray-600">
                  <div className="flex items-center">
                    <Skull className="w-4 h-4 mr-2 text-red-500" />
                    Last Place
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {recordBook.map((record) => (
                <tr key={record.season} className="hover:bg-gray-50">
                  <td className="px-6 py-4">
                    <span className="text-lg font-bold">{record.season}</span>
                  </td>
                  <td className="px-6 py-4">
                    <div>
                      <div className="font-semibold">{record.topThree[0].name}</div>
                      <div className="text-sm text-gray-500">
                        {record.topThree[0].wins}-{record.topThree[0].losses}
                        <span className="ml-2">({record.topThree[0].points.toFixed(1)} pts)</span>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div>
                      <div className="font-semibold">{record.topThree[1].name}</div>
                      <div className="text-sm text-gray-500">
                        {record.topThree[1].wins}-{record.topThree[1].losses}
                        <span className="ml-2">({record.topThree[1].points.toFixed(1)} pts)</span>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div>
                      <div className="font-semibold">{record.topThree[2].name}</div>
                      <div className="text-sm text-gray-500">
                        {record.topThree[2].wins}-{record.topThree[2].losses}
                        <span className="ml-2">({record.topThree[2].points.toFixed(1)} pts)</span>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div>
                      <div className="font-semibold">{record.highestScorer.name}</div>
                      <div className="text-sm text-gray-500">
                        {record.highestScorer.points.toFixed(1)} points
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div>
                      <div className="font-semibold text-red-500">{record.lastPlace.name}</div>
                      <div className="text-sm text-gray-500">
                        {record.lastPlace.wins}-{record.lastPlace.losses}
                        <span className="ml-2">({record.lastPlace.points.toFixed(1)} pts)</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default RecordBook;