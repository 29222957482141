import React, { createContext, useContext, useState } from 'react';

interface ModalContextType {
  isTypeFormOpen: boolean;
  openTypeForm: () => void;
  closeTypeForm: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isTypeFormOpen, setIsTypeFormOpen] = useState(false);

  const openTypeForm = () => setIsTypeFormOpen(true);
  const closeTypeForm = () => setIsTypeFormOpen(false);

  return (
    <ModalContext.Provider value={{ isTypeFormOpen, openTypeForm, closeTypeForm }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};