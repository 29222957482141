import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { db, League } from '../../services/firebase';
import { doc, getDoc } from 'firebase/firestore';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import { validateInviteAttempt, associateTeamWithEmail } from '../../services/inviteService';
import { createVerificationAttempt, verifyCode } from '../../services/emailVerificationService';
import { AlertCircle, CheckCircle } from 'lucide-react';
import {YahooLeague} from '../../types/yahoo'; 

interface TeamAssociation {
  teamId: string;
  email: string;
  name: string;
  joinedAt: number;
}

type YahooTeam = YahooLeague['teams'][0];

type VerificationStep = 'initial' | 'verifying' | 'complete';

interface TeamSelectorProps {
  teams: YahooTeam[];
  existingAssociations: TeamAssociation[];
  selectedTeam: string;
  onChange: (teamId: string, teamName: string) => void;
  disabled?: boolean;
}

const TeamSelector: React.FC<TeamSelectorProps> = ({
  teams,
  existingAssociations,
  selectedTeam,
  onChange,
  disabled = false
}) => {
  const availableTeams = useMemo(() => {
    if (!Array.isArray(teams)) {
      console.warn('Teams prop is not an array:', teams);
      return [];
    }

    // Keep existing filtering logic that works
    return teams.filter(team => {
      return !existingAssociations.some(assoc => 
        assoc.teamId === String(team.team_id)
      );
    });
  }, [teams, existingAssociations]);

  const handleTeamChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTeamId = event.target.value;
    console.log('Selected team ID from dropdown:', selectedTeamId, typeof selectedTeamId);

    // Keep existing team lookup that works
    const selectedTeamData = availableTeams.find(team => 
      String(team.team_id) === selectedTeamId
    );

    console.log('Found team data:', selectedTeamData);

    if (selectedTeamData) {
      // Keep existing string conversion that works
      onChange(String(selectedTeamData.team_id), selectedTeamData.name);
    } else {
      console.warn('Team not found for ID:', selectedTeamId);
      console.log('Available team IDs:', availableTeams.map(t => String(t.team_id)));
    }
  };

  return (
    <div>
      <label htmlFor="team" className="block text-sm font-medium text-gray-700">
        Select Your Team
      </label>
      <select
        id="team"
        value={selectedTeam || ''}
        onChange={handleTeamChange}
        className={`mt-1 block w-full rounded-md border border-gray-300 shadow-sm 
          focus:border-[#18f7b1] focus:ring focus:ring-[#18f7b1] focus:ring-opacity-50
          ${disabled ? 'bg-gray-100 text-gray-500' : 'bg-white'}
        `}
        required
        disabled={disabled}
      >
        <option value="">Select a team</option>
        {availableTeams.map(team => (
          <option 
            key={String(team.team_id)}
            value={String(team.team_id)}
          >
            {team.name}
          </option>
        ))}
      </select>
      {availableTeams.length > 0 && (
        <p className="mt-1 text-sm text-gray-500">
          {availableTeams.length} team{availableTeams.length !== 1 ? 's' : ''} available
        </p>
      )}
    </div>
  );
};

const TeamSelection: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const inviteCode = searchParams.get('code');
  
  const [league, setLeague] = useState<League | null>(null);
  const [selectedTeam, setSelectedTeam] = useState<string>('');
  const [selectedTeamName, setSelectedTeamName] = useState<string>('');
  const [email, setEmail] = useState('');
  const [verificationStep, setVerificationStep] = useState<VerificationStep>('initial');
  const [verificationId, setVerificationId] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [existingAssociations, setExistingAssociations] = useState<TeamAssociation[]>([]);
  const [isValidating, setIsValidating] = useState(true);
  const [resendTimeout, setResendTimeout] = useState(0);

  useEffect(() => {
    const validateAndFetchLeague = async () => {
      if (!id || !inviteCode) {
        setError('Invalid invite link');
        setIsValidating(false);
        return;
      }

      try {
        const leagueDoc = await getDoc(doc(db, 'leagues', id));
        if (!leagueDoc.exists()) {
          setError('League not found');
          setIsValidating(false);
          return;
        }

        const leagueData = { id: leagueDoc.id, ...leagueDoc.data() } as League;
        
        const validation = await validateInviteAttempt(id, inviteCode, 'browser');
        if (!validation.isValid) {
          setError(validation.error || 'Invalid invite code');
          setIsValidating(false);
          return;
        }

        setLeague(leagueData);
        setExistingAssociations(leagueData.teamAssociations || []);
      } catch (err) {
        console.error('Error validating invite:', err);
        setError('Failed to validate invite');
      } finally {
        setIsValidating(false);
      }
    };

    validateAndFetchLeague();
  }, [id, inviteCode]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (resendTimeout > 0) {
      timer = setInterval(() => {
        setResendTimeout(prev => prev - 1);
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [resendTimeout]);

  const handleTeamSelect = (teamId: string, teamName: string) => {
    console.log('Setting team:', { teamId, teamName, type: typeof teamId });
    setSelectedTeam(teamId);
    setSelectedTeamName(teamName);
  };

  const handleInitialSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!league?.yahooLeague?.teams || !id || !inviteCode) return;
  
    setIsSubmitting(true);
    setError(null);
  
    try {
      // Check existing associations
      if (existingAssociations.some(assoc => assoc.email.toLowerCase() === email.toLowerCase())) {
        setError('This email is already associated with a team in this league');
        return;
      }
  
      if (existingAssociations.some(assoc => assoc.teamId === selectedTeam)) {
        setError('This team has already been claimed');
        return;
      }
  
      // Add debug logging
      console.log('Looking up team:', {
        selectedTeam,
        teamIds: league.yahooLeague.teams.map(t => ({
          id: String(t.team_id),
          name: t.name
        }))
      });
  
      // Use String() for consistent comparison
      const teamData = league.yahooLeague.teams.find(team => 
        String(team.team_id) === String(selectedTeam)
      );
      
      if (!teamData) {
        console.error('Team lookup failed:', {
          selectedTeam,
          availableTeams: league.yahooLeague.teams.map(t => ({
            id: String(t.team_id),
            name: t.name
          }))
        });
        setError('Selected team not found in league data');
        return;
      }
  
      const { verificationId } = await createVerificationAttempt(
        email.toLowerCase(),
        id,
        selectedTeam,
        teamData.name,
        league.yahooLeague.name || league.name
      );
  
      setVerificationId(verificationId);
      setVerificationStep('verifying');
      setResendTimeout(60);
    } catch (err) {
      console.error('Error starting verification:', err);
      setError('Failed to send verification code. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
  

  const handleResendCode = async () => {
    if (!league?.yahooLeague?.teams || !id || !email || !selectedTeam) return;
  
    setIsSubmitting(true);
    setError(null);
  
    try {
      // Find the team in the Yahoo league data to ensure we have the correct name
      const teamData = league.yahooLeague.teams.find(team => team.team_id === selectedTeam);
      
      if (!teamData) {
        setError('Selected team not found in league data');
        return;
      }
  
      const { verificationId } = await createVerificationAttempt(
        email.toLowerCase(),
        id,
        selectedTeam,
        teamData.name, // Use the name directly from the team data
        league.yahooLeague.name || league.name
      );
  
      setVerificationId(verificationId);
      setResendTimeout(60);
      setError(null);
    } catch (err) {
      console.error('Error resending code:', err);
      setError('Failed to resend verification code. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleVerifyCode = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!verificationId || !verificationCode) return;

    setIsSubmitting(true);
    setError(null);

    try {
      const result = await verifyCode(verificationId, verificationCode);

      if (result.isValid && result.verificationData) {
        const { email, leagueId, teamId, teamName } = result.verificationData;

        const associationResult = await associateTeamWithEmail(
          leagueId,
          teamId,
          email,
          teamName,
          inviteCode!
        );

        if (associationResult.success) {
          setSuccess(true);
          setVerificationStep('complete');
          setExistingAssociations(prev => [
            ...prev,
            {
              teamId,
              email,
              name: teamName,
              joinedAt: Date.now()
            }
          ]);
        } else {
          setError(associationResult.error || 'Failed to associate team');
        }
      } else {
        setError(result.error || 'Invalid verification code');
      }
    } catch (err) {
      console.error('Error verifying code:', err);
      setError('Failed to verify code. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderInitialForm = () => (
    <form onSubmit={handleInitialSubmit} className="space-y-4">
      {league?.yahooLeague?.teams && (
 <TeamSelector
 teams={league?.yahooLeague?.teams || []}
 existingAssociations={existingAssociations}
 selectedTeam={selectedTeam}
 onChange={handleTeamSelect}
 disabled={isSubmitting}
/>
      )}
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Your Email
        </label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[#18f7b1] focus:ring focus:ring-[#18f7b1] focus:ring-opacity-50"
          required
        />
      </div>

      {error && (
        <div className="bg-red-100 text-red-700 p-3 rounded flex items-center gap-2">
          <AlertCircle size={20} />
          {error}
        </div>
      )}

      <button
        type="submit"
        disabled={isSubmitting || !selectedTeam || !email}
        className={`w-full bg-[#18f7b1] text-black py-2 px-4 rounded-lg hover:bg-[#0fd494] transition duration-300 ${
          (isSubmitting || !selectedTeam || !email) ? 'opacity-50 cursor-not-allowed' : ''
        }`}
      >
        {isSubmitting ? 'Sending verification...' : 'Next'}
      </button>
    </form>
  );

  const renderVerificationForm = () => (
    <form onSubmit={handleVerifyCode} className="space-y-4">
      <div>
        <label htmlFor="verificationCode" className="block text-sm font-medium text-gray-700">
          Enter Verification Code
        </label>
        <p className="text-sm text-gray-500 mb-2">
          We've sent a verification code to {email}
        </p>
        <input
          type="text"
          id="verificationCode"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value.replace(/\D/g, '').slice(0, 6))}
          className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[#18f7b1] focus:ring focus:ring-[#18f7b1] focus:ring-opacity-50"
          placeholder="Enter 6-digit code"
          required
          pattern="\d{6}"
        />
      </div>

      {error && (
        <div className="bg-red-100 text-red-700 p-3 rounded flex items-center gap-2">
          <AlertCircle size={20} />
          {error}
        </div>
      )}

      <div className="flex justify-between items-center">
        <button
          type="submit"
          disabled={isSubmitting || verificationCode.length !== 6}
          className={`bg-[#18f7b1] text-black py-2 px-4 rounded-lg hover:bg-[#0fd494] transition duration-300 ${
            (isSubmitting || verificationCode.length !== 6) ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isSubmitting ? 'Verifying...' : 'Verify Code'}
        </button>

        <button
          type="button"
          onClick={handleResendCode}
          disabled={resendTimeout > 0 || isSubmitting}
          className={`text-sm text-gray-600 hover:text-gray-900 ${
            resendTimeout > 0 || isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {resendTimeout > 0 
            ? `Resend code in ${resendTimeout}s` 
            : 'Resend code'}
        </button>
      </div>

      <button
        type="button"
        onClick={() => setVerificationStep('initial')}
        className="text-sm text-gray-500 hover:text-gray-700"
      >
        ← Back to team selection
      </button>
    </form>
  );

  const renderSuccessState = () => (
    <div className="text-center space-y-4">
      <div className="flex justify-center">
        <CheckCircle size={48} className="text-green-500" />
      </div>
      <h2 className="text-2xl font-bold text-gray-900">Team Claimed Successfully!</h2>
      <p className="text-gray-600">
        You've successfully claimed your team in {league?.yahooLeague?.name}.
      </p>
      <button
        onClick={() => navigate(`/league/${id}`)}
        className="bg-[#18f7b1] text-black py-2 px-4 rounded-lg hover:bg-[#0fd494] transition duration-300"
      >
        View League
      </button>
    </div>
  );

  if (isValidating) {
    return (
      <div className="min-h-screen bg-white">
        <Navbar />
        <div className="container mx-auto px-4 py-8 mt-16">
          <div className="text-center">
            <p className="text-lg text-gray-600">
              Validating invite...
            </p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  if (!league?.yahooLeague) {
    return (
      <div className="min-h-screen bg-white">
        <Navbar />
        <div className="container mx-auto px-4 py-8 mt-16">
          <div className="text-center">
            <div className="bg-red-100 text-red-700 p-4 rounded-lg mb-4 inline-flex items-center">
              <AlertCircle className="mr-2" />
              {error || 'Invalid invite link'}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      <div className="container mx-auto px-4 py-8 mt-16">
      <div className="max-w-md mx-auto bg-white rounded-lg shadow-lg p-6">
          <h1 className="text-3xl font-bold text-center mb-6 font-eau">
            {league.yahooLeague.name}
          </h1>
          
          {verificationStep === 'complete' ? renderSuccessState() :
           verificationStep === 'verifying' ? renderVerificationForm() :
           renderInitialForm()}
          
          {existingAssociations.length > 0 && verificationStep !== 'complete' && (
            <div className="mt-8 border-t pt-6">
              <h2 className="text-xl font-semibold mb-4">Claimed Teams</h2>
              <ul className="space-y-2">
                {existingAssociations.map((assoc, index) => (
                  <li key={index} className="flex justify-between items-center py-2 border-b border-gray-100">
                    <span className="font-medium text-gray-700">{assoc.name}</span>
                    <span className="text-sm text-gray-500">Claimed</span>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="mt-6 pt-4 border-t border-gray-200 text-sm text-gray-500">
            <p className="text-center">
              Only team managers should claim their teams. Claiming a team gives you 
              access to special features and notifications for your league.
            </p>
          </div>
        </div>

        <div className="max-w-md mx-auto mt-4">
          <div className="bg-gray-50 rounded-lg p-4 text-sm text-gray-600">
            <h3 className="font-semibold mb-2">Having trouble?</h3>
            <ul className="list-disc list-inside space-y-1">
              <li>Make sure you're using the email associated with your Yahoo Fantasy account</li>
              <li>Check your spam folder for the verification email</li>
              <li>Contact your league commissioner if you can't find your team</li>
              <li>The verification code expires after 15 minutes</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TeamSelection;