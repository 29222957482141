import React, { useState, useRef, useEffect } from 'react';
import { SocialMediaLink } from '../types';
import { ArrowLeftCircle, ArrowRightCircle, X } from 'lucide-react';

declare global {
  interface Window {
    instgrm?: {
      Embeds: {
        process: () => void;
      };
    };
    twttr?: {
      widgets: {
        load: () => void;
      };
    };
  }
}

interface SocialMediaPreviewProps {
  links: SocialMediaLink[];
  isEditing?: boolean;
  onDelete?: (id: string) => void;
}

const SocialMediaPreview: React.FC<SocialMediaPreviewProps> = ({
  links,
  isEditing = false,
  onDelete
}) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const loadedPlatforms = useRef<Set<string>>(new Set());

  const loadPlatformScript = (platform: string, url: string): (() => void) => {
    if (loadedPlatforms.current.has(platform)) {
      return () => {};
    }
  
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.id = `${platform}-script`;
    document.body.appendChild(script);
    loadedPlatforms.current.add(platform);
  
    return () => {
      const existingScript = document.getElementById(`${platform}-script`);
      if (existingScript) {
        document.body.removeChild(existingScript);
        loadedPlatforms.current.delete(platform);
      }
    };
  };

  useEffect(() => {
    const cleanupFns: Array<() => void> = [];

    links.forEach(link => {
      switch (link.type) {
        case 'instagram':
          cleanupFns.push(
            loadPlatformScript('instagram', '//www.instagram.com/embed.js')
          );
          break;
        case 'twitter':
          cleanupFns.push(
            loadPlatformScript('twitter', 'https://platform.twitter.com/widgets.js')
          );
          break;
        case 'tiktok':
          cleanupFns.push(
            loadPlatformScript('tiktok', 'https://www.tiktok.com/embed.js')
          );
          break;
      }
    });

    const reloadWidgets = () => {
      window.instgrm?.Embeds?.process?.();
      window.twttr?.widgets?.load?.();
    };

    // Initial load
    reloadWidgets();

    // Reload on any changes
    const observer = new MutationObserver(reloadWidgets);
    if (carouselRef.current) {
      observer.observe(carouselRef.current, {
        childList: true,
        subtree: true
      });
    }

    return () => {
      cleanupFns.forEach(cleanup => cleanup());
      observer.disconnect();
    };
  }, [links]);

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setStartX(e.pageX - (carouselRef.current?.offsetLeft || 0));
    setScrollLeft(carouselRef.current?.scrollLeft || 0);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return;
    e.preventDefault();
    if (!carouselRef.current) return;
    
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    carouselRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleScroll = (direction: 'left' | 'right') => {
    if (!carouselRef.current) return;
    const scrollAmount = carouselRef.current.offsetWidth;
    const newScrollLeft = direction === 'left' 
      ? carouselRef.current.scrollLeft - scrollAmount
      : carouselRef.current.scrollLeft + scrollAmount;
    
    carouselRef.current.scrollTo({
      left: newScrollLeft,
      behavior: 'smooth'
    });
  };

  const getEmbedComponent = (link: SocialMediaLink) => {
    try {
      const url = new URL(link.url);
      
      switch(link.type) {
        case 'instagram': {
          return (
            <div className="w-full flex items-center justify-center">
              <div className="w-full max-w-[328px] sm:max-w-[540px] flex items-center">
                <blockquote 
                  className="instagram-media !m-0 !min-w-0 w-full"
                  data-instgrm-permalink={link.url}
                  data-instgrm-version="14"
                  data-instgrm-captioned
                  style={{ 
                    margin: '0 !important',
                    width: '100% !important',
                    minWidth: '0 !important',
                    border: 'none !important',
                  }}
                />
              </div>
            </div>
          );
        }

        case 'youtube': {
          const videoId = url.searchParams.get('v') || url.pathname.split('/').pop();
          return (
            <div className="relative w-full pt-[56.25%]">
              <iframe
                className="absolute top-0 left-0 w-full h-full"
                src={`https://www.youtube.com/embed/${videoId}`}
                allowFullScreen
                loading="lazy"
                title={`YouTube video ${videoId}`}
              />
            </div>
          );
        }
        case 'tiktok': {
          const videoId = url.pathname.split('/').pop();
          return (
            <blockquote 
              className="tiktok-embed !m-0"
              cite={link.url}
              data-video-id={videoId}
              style={{ 
                maxWidth: '100% !important',
                minWidth: 'unset !important'
              }}
            >
              <section></section>
            </blockquote>
          );
        }
        default:
          return null;
      }
    } catch (error) {
      console.error('Error parsing social media URL:', error);
      return null;
    }
  };

  if (!links || links.length === 0) return null;

return (
  <div className="relative w-screen -ml-[50vw] left-1/2 px-2 sm:px-4">
    <div className="max-w-[2000px] mx-auto">
      <div 
        ref={carouselRef}
        className="flex overflow-x-auto hide-scrollbar gap-2 sm:gap-4 mb-4 sm:mb-6"
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        style={{
          cursor: isDragging ? 'grabbing' : 'grab',
          scrollSnapType: 'x mandatory',
          WebkitOverflowScrolling: 'touch'
        }}
      >
        {links.map((link, index) => (
          <div 
            key={link.id || `link-${index}`}
            className="flex-shrink-0 w-[328px] sm:w-[350px] lg:w-[400px]"
            style={{ scrollSnapAlign: 'start' }}
          >
            <div className="p-2 sm:p-4">
              <div 
                className={`
                  relative bg-white rounded-[20px] sm:rounded-[29px] 
                  overflow-hidden shadow-lg transition-all duration-300 
                  hover:shadow-xl hover:scale-[1.02]
                `}
              >
                {isEditing && onDelete && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete(link.id);
                    }}
                    className="absolute top-2 right-2 sm:top-4 sm:right-4 z-10 p-1 
                             bg-red-500 rounded-full text-white hover:bg-red-600 
                             transition-colors"
                    aria-label="Delete"
                  >
                    <X size={16} className="sm:w-5 sm:h-5" />
                  </button>
                )}
                
                <div className="relative w-full">
                  {/* Container for Instagram */}
                  {link.type === 'instagram' && (
  <div className="w-full max-h-[600px] sm:max-h-[700px]">
    <div className="w-full h-full flex items-center justify-center p-2 sm:p-4">
      <div className="w-full max-w-[328px] sm:max-w-[540px] overflow-hidden">
        <blockquote 
          className="instagram-media !m-0 !min-w-0 w-full"
          data-instgrm-permalink={link.url}
          data-instgrm-version="14"
          data-instgrm-captioned
          style={{ 
            margin: '0 !important',
            width: '100% !important',
            minWidth: '0 !important',
            border: 'none !important',
            maxHeight: '600px',
            overflowY: 'auto',
            overflowX: 'hidden',
            WebkitOverflowScrolling: 'touch'
          }}
        />
      </div>
    </div>
  </div>
)}

                  {/* Container for Twitter */}
                  {link.type === 'twitter' && (
                    <div className="w-full min-h-[500px] sm:min-h-[600px]">
                      <div className="w-full h-full flex items-center justify-center p-2 sm:p-4">
                        <div className="w-full max-w-[350px] sm:max-w-[550px]">
                          <blockquote 
                            className="twitter-tweet !m-0"
                            data-conversation="none"
                            style={{
                              width: '100% !important'
                            }}
                          >
                            <a href={link.url}></a>
                          </blockquote>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Container for YouTube */}
                  {link.type === 'youtube' && (
                    <div className="w-full aspect-video">
                      <iframe
                        className="w-full h-full"
                        src={`https://www.youtube.com/embed/${
                          new URL(link.url).searchParams.get('v') || 
                          new URL(link.url).pathname.split('/').pop()
                        }`}
                        allowFullScreen
                        loading="lazy"
                        title={`YouTube video ${link.id}`}
                      />
                    </div>
                  )}

                  {/* Container for TikTok */}
                 {link.type === 'tiktok' && (
  <div className="w-full max-h-[600px] sm:max-h-[700px]">
    <div className="w-full h-full flex items-center justify-center p-2 sm:p-4">
      <div className="w-full max-w-[328px] sm:max-w-[540px] overflow-hidden">
        <blockquote 
          className="tiktok-embed !m-0 !min-w-0 w-full"
          cite={link.url}
          data-video-id={new URL(link.url).pathname.split('/').pop()}
          style={{ 
            margin: '0 !important',
            width: '100% !important',
            minWidth: '0 !important',
            border: 'none !important',
            maxHeight: '600px',
            overflowY: 'auto',
            overflowX: 'hidden',
            WebkitOverflowScrolling: 'touch'
          }}
        >
          <section></section>
        </blockquote>
      </div>
    </div>
  </div>
)}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {links.length > 1 && (
        <div className="flex justify-center gap-2 sm:gap-4 mt-2 sm:mt-4">
          <button 
            onClick={() => handleScroll('left')}
            className="hover:scale-105 transition-transform duration-200"
            aria-label="Previous"
          >
            <ArrowLeftCircle strokeWidth="1.3" className="w-8 h-8 sm:w-12 sm:h-12 text-white" />
          </button>
          <button 
            onClick={() => handleScroll('right')}
            className="hover:scale-105 transition-transform duration-200"
            aria-label="Next"
          >
            <ArrowRightCircle strokeWidth="1.3" className="w-8 h-8 sm:w-12 sm:h-12 text-white" />
          </button>
        </div>
      )}
    </div>

    <style>{`
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  @media (max-width: 640px) {
    .instagram-media,
    .twitter-tweet,
    .tiktok-embed {
      max-width: 100% !important;
      min-width: 0 !important;
      width: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    
    .instagram-media iframe,
    .twitter-tweet iframe,
    .tiktok-embed iframe {
      min-width: 0 !important;
    }
  }
`}</style>
  </div>
);
};

export default SocialMediaPreview;