import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPageView, trackEvent } from '../analyticsConfig'

import { useAuth } from '../contexts/AuthContext';

interface FirebaseAnalyticsProviderProps {
  children: ReactNode;
}

export const FirebaseAnalyticsProvider = ({ children }: FirebaseAnalyticsProviderProps) => {
  const location = useLocation();
  const { user } = useAuth(); // Get the current user
  const [initialized, setInitialized] = useState(false);

  // Initialize analytics and set user properties
  useEffect(() => {
    const initializeAnalytics = async () => {
      if (!initialized) {
        await trackEvent('app_initialized', {
          environment: process.env.NODE_ENV
        });
        setInitialized(true);
      }
    };

    initializeAnalytics();
  }, [initialized]);

  // Track user authentication state
  useEffect(() => {
    if (user && initialized) {
      trackEvent('user_authenticated', {
        user_id: user.uid,
        user_email: user.email,
      });
    }
  }, [user, initialized]);

  // Track page views
  useEffect(() => {
    if (initialized) {
      trackPageView(location.pathname);
      
      // Track additional page data
      trackEvent('page_metadata', {
        path: location.pathname,
        search: location.search,
        hash: location.hash,
        timestamp: new Date().toISOString()
      });
    }
  }, [location, initialized]);

  return <>{children}</>;
};