import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { Widget } from '@typeform/embed-react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useNavigate } from 'react-router-dom';

interface LeagueRegistrationSliderProps {
  isOpen: boolean;
  onClose: () => void;
}

const LeagueRegistrationSlider: React.FC<LeagueRegistrationSliderProps> = ({ 
  isOpen, 
  onClose 
}) => {
  const [mounted, setMounted] = useState(false);
  const [isCommissioner, setIsCommissioner] = useState<boolean | null>(null);
  const [isSignedUp, setIsSignedUp] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  // Handle mounting animation
  useEffect(() => {
    if (isOpen) {
      setMounted(true);
    } else {
      const timer = setTimeout(() => {
        setMounted(false);
        setIsCommissioner(null); // Reset state when closing
        setIsSignedUp(false);
        setEmail('');
        setMessage('');
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage('');
    setIsLoading(true);

    try {
      // Add email to newsletter collection
      const docRef = await addDoc(collection(db, 'newsletter'), {
        email: email.toLowerCase().trim(),
        subscribedAt: new Date(),
        source: 'registration_slider'
      });
      
      setMessage('Successfully subscribed!');
      setEmail('');
      // Only set signup to true after successful Firebase operation
      setIsSignedUp(true);
    } catch (error: any) {
      console.error('Newsletter subscription error:', error);
      setMessage(`Error: ${error.message}`);
      setIsSignedUp(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCommissionerClick = () => {
    onClose(); // Close the slider
    navigate('/sign-up'); // Navigate to sign-up page
  };

  if (!mounted) return null;

  const renderNewsletterForm = () => (
    <div className="h-full flex flex-col justify-center px-4 md:px-16">
      <div className="max-w-md mx-auto w-full">
        <h1 className="font-antonio text-white text-lg mb-8 font-bold">
          Sign up for the Fantasy Loser waitlist and receive our newsletter for more punishments, 
          weekly challenges, and early access to new features!
        </h1>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="relative">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Subscribe to our newsletter →"
              className="w-full bg-transparent border border-white/20 rounded-lg py-3 px-4 
                      placeholder-gray-500 focus:outline-none focus:border-[#18f7b1]
                      transition-colors font-antonio text-white"
              required
            />
          </div>

          <button 
            type="submit" 
            disabled={isLoading}
            className="w-auto border-2 border-white text-white px-8 sm:px-10 py-4 
                    rounded-[10px] hover:text-black hover:bg-white hover:border-black 
                    transition-colors duration-300 font-antonio text-lg sm:text-xl lg:text-xl
                    disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isLoading ? 'Submitting...' : 'Submit'}
          </button>

          {message && (
            <p className={`text-sm ${
              message.startsWith('Error') ? 'text-red-500' : 'text-[#18f7b1]'
            }`}>
              {message}
            </p>
          )}
        </form>
      </div>
    </div>
  );

  const renderCommissionerChoice = () => (
    <div className="h-full flex flex-col justify-center">
      <div className="max-w-md mx-auto w-full text-center">
      <h1 className="font-antonio text-white text-[32px] mb-8 font-bold leading-[80px]">
          Thank you for joining our waitlist
        </h1>
        <h1 className="font-antonio text-white text-[72px] mb-4 font-bold leading-[80px]">
          Are you a League Commissioner?
        </h1>
        <h1 className="font-antonio text-white text-[32px] mb-8 font-bold leading-[80px]">
        And do you want to be involved in our early access community?
        </h1>
        
        <div className="flex flex-col space-y-4 mt-12">
          <button
            onClick={handleCommissionerClick}
            className="w-full border-2 border-[#18f7b1] bg-[#18f7b1] text-black px-8 py-4 
                     rounded-[10px] hover:bg-transparent hover:text-[#18f7b1] transition-colors duration-300
                     font-antonio text-xl"
          >
            Yes, I'm a Commissioner
          </button>
          
          <button
            onClick={() => setIsCommissioner(false)}
            className="w-full border-2 border-white text-white px-8 py-4 
                     rounded-[10px] hover:bg-white hover:text-black transition-colors duration-300
                     font-antonio text-xl"
          >
            No, I'm a League Member
          </button>
        </div>
      </div>
    </div>
  );

  const renderNonCommissionerMessage = () => (
    <div className="h-full flex flex-col justify-center">
      <div className="max-w-md mx-auto w-full text-center">
        <h1 className="font-antonio text-white text-[72px] mb-8 font-bold leading-[80px]">
          Commissioner Access Only
        </h1>
        
        <p className="text-white/80 text-xl font-antonio mb-8">
          Currently, we're only accepting league commissioners during our beta period. 
          Please have your league commissioner register your league.
        </p>
        
        <button
          onClick={() => setIsCommissioner(null)}
          className="w-full border-2 border-white text-white px-8 py-4 
                   rounded-[10px] hover:bg-white hover:text-black transition-colors duration-300
                   font-antonio text-xl"
        >
          Go Back
        </button>
      </div>
    </div>
  );

  const renderContent = () => {
    if (!isSignedUp) {
      return renderNewsletterForm();
    }
    
    if (isCommissioner === null) {
      return renderCommissionerChoice();
    }
    
    if (isCommissioner === false) {
      return renderNonCommissionerMessage();
    }
    
    return null; // Remove typeform rendering since we're navigating instead
  };

  return (
    <div className="fixed inset-0 z-50 overflow-hidden">
      {/* Backdrop */}
      <div 
        className={`absolute inset-0 bg-black/50 transition-opacity duration-500 ease-in-out 
          ${isOpen ? 'opacity-100' : 'opacity-0'}`}
        onClick={onClose}
      />
      
      {/* Panel */}
      <div 
        className={`absolute top-0 right-0 h-full w-full md:w-[712px] bg-[#1A1A1A] 
          transform transition-transform duration-500 ease-in-out rounded-3xl
          ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
      >
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-8 left-10 text-black bg-[#18f7b1] hover:bg-white 
                   transition-colors p-1 rounded-full group z-50"
          aria-label="Close registration panel"
        >
          <X 
            size={24} 
            className="group-hover:text-black transition-colors"
          />
        </button>

        {/* Content */}
        <div className="h-full overflow-y-auto">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default LeagueRegistrationSlider;