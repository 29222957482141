import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../services/firebase';

const Slider: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [mounted, setMounted] = useState(false);

  // Handle mounting animation
  useEffect(() => {
    if (isOpen) {
      setMounted(true);
    } else {
      const timer = setTimeout(() => {
        setMounted(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage('');
    setIsLoading(true);

    try {
      const docRef = await addDoc(collection(db, 'newsletter'), {
        email: email,
        subscribedAt: new Date()
      });
      setMessage('Successfully subscribed!');
      setEmail('');
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error: any) {
      console.error('Newsletter subscription error:', error);
      setMessage(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  if (!mounted) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-hidden">
      {/* Backdrop */}
      <div 
        className={`absolute inset-0 bg-black/50 transition-opacity duration-500 ease-in-out 
        ${isOpen ? 'opacity-100' : 'opacity-0'}`}
        onClick={onClose}
      />
      
      {/* Panel */}
      <div 
        className={`absolute top-0 right-0 h-full w-full md:w-[712px] bg-[#1A1A1A] 
        transform transition-transform duration-500 ease-in-out rounded-3xl
        ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
      >
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-8 left-10 text-black bg-[#18f7b1] hover:bg-white 
                   transition-colors p-1 rounded-full group"
          aria-label="Close newsletter panel"
        >
          <X 
            size={24} 
            className="group-hover:text-black transition-colors"
          />
        </button>

        {/* Content */}
        <div className="h-full flex flex-col justify-center px-4 md:px-16 ">
          <div className="max-w-md mx-auto w-full">
            <h1 className="font-antonio text-white text-[120px] mb-8 font-bold leading-[132px] ">
              Subscribe to our Newsletter
            </h1>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="relative">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Subscribe to our newsletter                                                          →"
                  className="w-full bg-transparent border border-white/20 rounded-lg py-3 px-4 
                           placeholder-gray-500 focus:outline-none focus:border-[#18f7b1]
                           transition-colors font-antonio text-white"
                  required
                />
              </div>

              <button 
                type="submit" 
                disabled={isLoading}
                className="w-auto border-2 border-white text-white px-8 sm:px-10 py-4 
                 rounded-[10px] hover:text-black hover:bg-white hover:border-black transition-colors duration-300
                 font-antonio text-lg sm:text-xl lg:text-xl ]"
              >
                {isLoading ? 'Submitting...' : 'Submit'}
              </button>

              {message && (
                <p className={`text-sm ${
                  message.startsWith('Error') ? 'text-red-500' : 'text-[#18f7b1]'
                }`}>
                  {message}
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;