import { EmailTemplate, EmailTemplateData } from '../types/sendgrid';

const TEMPLATE_IDS: Record<EmailTemplate, string> = {
  'team-verification': 'd-42fdc836c7414bd4b88a7fd35108c31f',
  'league-invitation': 'd-42fdc836c7414bd4b88a7fd35108c31f'
};

async function sendTemplatedEmail(
  template: EmailTemplate,
  config: {
    to: string;
    subject: string;
    templateData: Omit<EmailTemplateData, 'subject'>;
  }
): Promise<boolean> {
  console.log('Starting sendTemplatedEmail with config:', {
    template,
    to: config.to,
    subject: config.subject,
    templateDataKeys: Object.keys(config.templateData)
  });

  try {
    // Simplified message structure that matches SendGrid's expectations
    const msg = {
      to: config.to,
      from: {
        email: 'noreply@fantasyloser.com',
        name: 'Fantasy Loser'
      },
      subject: config.subject,
      template_id: TEMPLATE_IDS[template],
      dynamicTemplateData: {
        ...config.templateData,
        subject: config.subject
      }
    };

    console.log('Sending message to Netlify function:', msg); // Debug log

    const response = await fetch('/.netlify/functions/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(msg)
    });

    const responseData = await response.json();
    
    if (!response.ok) {
      console.error('SendGrid API Error Response:', responseData);
      throw new Error(
        `SendGrid API error (${response.status}): ${responseData.error || responseData.message || 'Unknown error'}`
      );
    }

    console.log('SendGrid API Success Response:', responseData); // Debug log
    return true;

  } catch (error) {
    console.error('SendGrid Service Error:', {
      error: error instanceof Error ? {
        name: error.name,
        message: error.message,
        stack: error.stack
      } : error,
      config: {
        template,
        to: config.to,
        subject: config.subject
      }
    });

    throw error;
  }
}

export async function sendVerificationEmail(
  email: string,
  code: string,
  teamName: string,
  leagueName: string
): Promise<boolean> {
  console.log('Sending verification email:', {
    email,
    teamName,
    leagueName,
    codeLength: code.length
  });

  try {
    return await sendTemplatedEmail('team-verification', {
      to: email,
      subject: `Verify Your ${leagueName} Team`,
      templateData: {
        verificationCode: code,
        teamName: teamName || 'Unknown Team',
        leagueName,
        expiryMinutes: 15
      }
    });
  } catch (error) {
    console.error('Failed to send verification email:', {
      error: error instanceof Error ? {
        name: error.name,
        message: error.message,
        stack: error.stack
      } : error,
      email,
      teamName,
      leagueName
    });
    throw error;
  }
}

export async function sendLeagueInvitation(
  email: string,
  leagueName: string,
  inviteLink: string,
  commissionerName: string,
  leagueYear: string
): Promise<boolean> {
  console.log('Sending league invitation:', {
    email,
    leagueName,
    commissionerName,
    leagueYear,
    inviteLinkDomain: new URL(inviteLink).hostname
  });

  try {
    return await sendTemplatedEmail('league-invitation', {
      to: email,
      subject: `Join ${leagueName} on Fantasy Loser`,
      templateData: {
        recipientEmail: email,
        leagueName,
        inviteLink,
        commissionerName,
        leagueYear
      }
    });
  } catch (error) {
    console.error('Failed to send league invitation:', {
      error: error instanceof Error ? {
        name: error.name,
        message: error.message,
        stack: error.stack
      } : error,
      email,
      leagueName
    });
    throw error;
  }
}