import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { Calculator, Calendar, Trophy, Skull } from 'lucide-react';

interface ChallengeHistoryEntry {
  challengeId: string;
  challengeTitle: string;
  leagueId: string;
  week: number;
  timestamp: Date;
  results: Array<{
    teamKey: string;
    teamName: string;
    totalPoints: number;
    calculations: Array<{
      name: string;
      result: number;
      breakdown: string;
    }>;
    isWinner: boolean;
    isLoser: boolean;
  }>;
}

interface ChallengeHistoryProps {
  leagueId: string;
}

export default function ChallengeHistory({ leagueId }: ChallengeHistoryProps) {
  const [history, setHistory] = useState<ChallengeHistoryEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedWeek, setSelectedWeek] = useState<number | 'all'>('all');
  const [selectedChallenge, setSelectedChallenge] = useState<string | 'all'>('all');

  const fetchHistory = async () => {
    setLoading(true);
    try {
      let historyQuery = query(
        collection(db, 'challengeHistory'),
        where('leagueId', '==', leagueId),
        orderBy('timestamp', 'desc')
      );

      const querySnapshot = await getDocs(historyQuery);
      const historyData = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        timestamp: doc.data().timestamp.toDate()
      })) as ChallengeHistoryEntry[];

      setHistory(historyData);
    } catch (err) {
      console.error('Error fetching challenge history:', err);
      setError('Failed to load challenge history');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHistory();
  }, [leagueId]);

  const getUniqueWeeks = () => {
    return Array.from(new Set(history.map(entry => entry.week))).sort((a, b) => b - a);
  };

  const getUniqueChallenges = () => {
    return Array.from(new Set(history.map(entry => entry.challengeId)))
      .map(id => ({
        id,
        title: history.find(entry => entry.challengeId === id)?.challengeTitle || 'Unknown Challenge'
      }));
  };

  const filteredHistory = history.filter(entry => {
    const weekMatch = selectedWeek === 'all' || entry.week === selectedWeek;
    const challengeMatch = selectedChallenge === 'all' || entry.challengeId === selectedChallenge;
    return weekMatch && challengeMatch;
  });

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#18f7b1]"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border-l-4 border-red-500 p-4">
        <p className="text-red-700">{error}</p>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto p-4 space-y-6">
      {/* Filters */}
      <div className="flex flex-wrap gap-4 bg-white p-4 rounded-lg shadow">
        <div>
          <label className="block text-sm font-medium mb-1">Week</label>
          <select
            value={selectedWeek}
            onChange={(e) => setSelectedWeek(e.target.value === 'all' ? 'all' : Number(e.target.value))}
            className="border rounded p-2"
          >
            <option value="all">All Weeks</option>
            {getUniqueWeeks().map(week => (
              <option key={week} value={week}>Week {week}</option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Challenge</label>
          <select
            value={selectedChallenge}
            onChange={(e) => setSelectedChallenge(e.target.value)}
            className="border rounded p-2"
          >
            <option value="all">All Challenges</option>
            {getUniqueChallenges().map(challenge => (
              <option key={challenge.id} value={challenge.id}>{challenge.title}</option>
            ))}
          </select>
        </div>
      </div>

      {/* History List */}
      <div className="space-y-6">
        {filteredHistory.map((entry, index) => (
          <div key={index} className="bg-white rounded-lg shadow overflow-hidden">
            <div className="bg-[#18f7b1] p-4">
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-bold">{entry.challengeTitle}</h3>
                <div className="flex items-center gap-2">
                  <Calendar size={16} />
                  <span>Week {entry.week}</span>
                </div>
              </div>
              <div className="text-sm mt-1">
                {new Date(entry.timestamp).toLocaleDateString()}
              </div>
            </div>

            <div className="p-4">
              <div className="grid md:grid-cols-2 gap-4">
                {entry.results.map((result, resultIndex) => (
                  <div 
                    key={resultIndex}
                    className={`p-4 rounded-lg ${
                      result.isWinner 
                        ? 'bg-green-50 border border-green-200' 
                        : result.isLoser
                          ? 'bg-red-50 border border-red-200'
                          : 'bg-gray-50 border border-gray-200'
                    }`}
                  >
                    <div className="flex justify-between items-center mb-3">
                      <div className="flex items-center gap-2">
                        {result.isWinner && <Trophy className="text-green-500" size={20} />}
                        {result.isLoser && <Skull className="text-red-500" size={20} />}
                        <h4 className="font-bold">{result.teamName}</h4>
                      </div>
                      <span className="text-xl font-bold">
                        {result.totalPoints.toFixed(2)}
                      </span>
                    </div>

                    <div className="space-y-2">
                      {result.calculations.map((calc, calcIndex) => (
                        <div key={calcIndex} className="text-sm bg-white p-2 rounded">
                          <div className="flex items-center gap-1 mb-1">
                            <Calculator size={14} />
                            <span className="font-medium">{calc.name}</span>
                          </div>
                          <div className="text-right font-mono text-gray-600">
                            {calc.result.toFixed(2)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}

        {filteredHistory.length === 0 && (
          <div className="text-center py-8 text-gray-500">
            No challenge history found
          </div>
        )}
      </div>
    </div>
  );
}