import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import LeagueRegistrationSlider from './ui/LeagueRegistrationSlider';

export default function ComingSoonSection() {
  const navigate = useNavigate();
  const [isRegistrationOpen, setIsRegistrationOpen] = useState(false);

  return (
    <section className="bg-white py-16">
      <div className="container mx-auto px-4">
        <div className="flex flex-col-reverse lg:grid lg:grid-cols-2 gap-8 lg:gap-16 items-center">
          {/* Content */}
          <div className="max-w-xl w-full">
            <h2 className="text-4xl lg:text-6xl font-bold leading-none font-antonio text-black mb-6 uppercase">
              Coming Soon
            </h2>
            <p className="text-lg lg:text-xl text-[#1a1a1a] mb-8 font-antonio">
              Get ready for the ultimate fantasy sports experience. We're building something special 
              that will revolutionize how you manage your fantasy leagues. Sign up now to be among 
              the first to know when we launch and receive exclusive early access.
            </p>
            <button 
              onClick={() => setIsRegistrationOpen(true)}
              className=" py-4 mt-6 text-black border border-2 border-black rounded-md
                        hover:bg-black hover:text-white transition-all duration-300 
                        font-antonio text-xl uppercase tracking-wide px-4"
            >
              GET ON THE COMMISSIONER WAITLIST
            </button>
          </div>

          {/* Image Card */}
          <div className="relative w-full mb-8 lg:mb-0">
            <div className="aspect-[690/578] w-full rounded-2xl overflow-hidden 
                          shadow-xl transition-transform duration-300 hover:scale-[1.02]">
              <div className="absolute inset-0" />
              <img 
                className="w-full h-full object-fill"
                src={require("../assets/ComingSoon.png")}
                alt="Fantasy Loser Cover"
              />
            </div>
          </div>
        </div>
      </div>
      <LeagueRegistrationSlider 
        isOpen={isRegistrationOpen}
        onClose={() => setIsRegistrationOpen(false)}
      />
    </section>
  );
}