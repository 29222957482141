import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, League } from './firebase';
import { nanoid } from 'nanoid';
import { sendLeagueInvitation } from './sendgrid-service';

const RATE_LIMIT_WINDOW = 300000; // 5 minutes in milliseconds
const MAX_ATTEMPTS = 10;

export interface TeamAssociation {
  teamId: string;
  email: string;
  name: string;
  joinedAt: number;
}

export const createInviteCode = () => {
  return nanoid(8);
};

export const generateNewInviteCode = async (leagueId: string): Promise<string> => {
  const newCode = createInviteCode();
  const leagueRef = doc(db, 'leagues', leagueId);
  
  await updateDoc(leagueRef, {
    inviteCode: newCode,
    inviteAttempts: {}
  });

  return newCode;
};

export const validateInviteAttempt = async (
  leagueId: string, 
  inviteCode: string, 
  ip: string
): Promise<{ isValid: boolean; error?: string }> => {
  const leagueRef = doc(db, 'leagues', leagueId);
  const leagueDoc = await getDoc(leagueRef);

  if (!leagueDoc.exists()) {
    return { isValid: false, error: 'League not found' };
  }

  const league = leagueDoc.data() as League;
  const now = Date.now();

  if (league.inviteCode !== inviteCode) {
    const attempts = league.inviteAttempts?.[ip] || { count: 0, lastAttempt: 0 };
    
    if (now - attempts.lastAttempt > RATE_LIMIT_WINDOW) {
      attempts.count = 0;
    }

    if (attempts.count >= MAX_ATTEMPTS) {
      return { 
        isValid: false, 
        error: 'Too many invalid attempts. Please try again in 5 minutes.' 
      };
    }

    const newAttempts = {
      ...league.inviteAttempts,
      [ip]: {
        count: attempts.count + 1,
        lastAttempt: now
      }
    };

    await updateDoc(leagueRef, { inviteAttempts: newAttempts });
    return { isValid: false, error: 'Invalid invite code' };
  }

  return { isValid: true };
};

export const sendTeamInvite = async (
  leagueId: string,
  email: string,
  inviteCode: string
): Promise<{ success: boolean; error?: string }> => {
  try {
    const leagueRef = doc(db, 'leagues', leagueId);
    const leagueDoc = await getDoc(leagueRef);

    if (!leagueDoc.exists()) {
      return { success: false, error: 'League not found' };
    }

    const league = leagueDoc.data() as League;
    
    // Check if email is already associated
    if (league.teamAssociations?.some(assoc => 
      assoc.email.toLowerCase() === email.toLowerCase()
    )) {
      return { success: false, error: 'Email already associated with a team' };
    }

    // Generate invite link
    const inviteLink = `${window.location.origin}/league/${leagueId}/join?code=${inviteCode}`;

    // Send invitation email
    await sendLeagueInvitation(
      email,
      league.name,
      inviteLink,
      league.commish,
      league.yearStarted
    );

    return { success: true };
  } catch (error) {
    console.error('Error sending team invite:', error);
    return { success: false, error: 'Failed to send invitation' };
  }
};

export const associateTeamWithEmail = async (
  leagueId: string,
  teamId: string,
  email: string,
  teamName: string,
  inviteCode: string
): Promise<{ success: boolean; error?: string }> => {
  try {
    const leagueRef = doc(db, 'leagues', leagueId);
    const leagueDoc = await getDoc(leagueRef);

    if (!leagueDoc.exists()) {
      return { success: false, error: 'League not found' };
    }

    const league = leagueDoc.data() as League;

    // Validate invite code
    if (league.inviteCode !== inviteCode) {
      return { success: false, error: 'Invalid invite code' };
    }

    // Check if email is already associated
    if (league.teamAssociations?.some(assoc => 
      assoc.email.toLowerCase() === email.toLowerCase()
    )) {
      return { success: false, error: 'Email already associated with a team' };
    }

    // Check if team is already claimed
    if (league.teamAssociations?.some(assoc => assoc.teamId === teamId)) {
      return { success: false, error: 'Team already claimed' };
    }

    // Create new association
    const newAssociation: TeamAssociation = {
      teamId,
      email: email.toLowerCase(),
      name: teamName,
      joinedAt: Date.now()
    };

    const updatedAssociations = [
      ...(league.teamAssociations || []),
      newAssociation
    ];

    await updateDoc(leagueRef, {
      teamAssociations: updatedAssociations
    });

    return { success: true };
  } catch (error) {
    console.error('Error associating team:', error);
    return { success: false, error: 'Failed to associate team' };
  }
};

// New utility functions

export const getTeamAssociation = async (
  leagueId: string,
  email: string
): Promise<TeamAssociation | null> => {
  const leagueRef = doc(db, 'leagues', leagueId);
  const leagueDoc = await getDoc(leagueRef);

  if (!leagueDoc.exists()) {
    return null;
  }

  const league = leagueDoc.data() as League;
  return league.teamAssociations?.find(
    assoc => assoc.email.toLowerCase() === email.toLowerCase()
  ) || null;
};

export const removeTeamAssociation = async (
  leagueId: string,
  email: string
): Promise<boolean> => {
  try {
    const leagueRef = doc(db, 'leagues', leagueId);
    const leagueDoc = await getDoc(leagueRef);

    if (!leagueDoc.exists()) {
      return false;
    }

    const league = leagueDoc.data() as League;
    const updatedAssociations = league.teamAssociations?.filter(
      assoc => assoc.email.toLowerCase() !== email.toLowerCase()
    ) || [];

    await updateDoc(leagueRef, {
      teamAssociations: updatedAssociations
    });

    return true;
  } catch (error) {
    console.error('Error removing team association:', error);
    return false;
  }
};