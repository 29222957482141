import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getWeeklyChallengeById } from '../services/firebase';
import { WeeklyChallenge } from '../components/WeeklyChallengeForm';
import { useTheme } from '../contexts/ThemeContext';
import { Share2 } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import StyledLoadingSpinner from '../components/ui/StyledLoadingSpinner';
import MetaHead from '../components/ui/MetaHead';



const ChallengeDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [challenge, setChallenge] = useState<WeeklyChallenge | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { theme } = useTheme();
  const [isSubtitleExpanded, setIsSubtitleExpanded] = useState(false);

  const renderTruncatedSubtitle = (text: string | undefined) => {
    if (!text) return null;
    
    const characterLimit = 300;
    const needsTruncation = text.length > characterLimit;
    const truncatedText = needsTruncation && !isSubtitleExpanded 
      ? `${text.slice(0, characterLimit).trim()}...` 
      : text;
  
    return (
      <div className="mb-6">
        <p className="text-xl text-gray-200 max-w-3xl font-antonio">
          {truncatedText}
        </p>
        {needsTruncation && (
          <button
            onClick={() => setIsSubtitleExpanded(!isSubtitleExpanded)}
            className="text-[#18f7b1] hover:text-[#0fd494] font-antonio transition-colors duration-200"
          >
            {isSubtitleExpanded ? 'Show Less' : 'Read More'}
          </button>
        )}
      </div>
    );
  };

  useEffect(() => {
    const fetchChallenge = async () => {
      if (id) {
        try {
          const fetchedChallenge = await getWeeklyChallengeById(id);
          setChallenge(fetchedChallenge);
        } catch (error) {
          console.error('Error fetching challenge:', error);
        }
        setIsLoading(false);
      }
    };

    fetchChallenge();
  }, [id]);

  const truncateDescription = (text: string, limit: number) => {
    if (!text) return '';
    if (text.length <= limit) return text;
    return text.slice(0, limit).trim() + '...';
  };

  const parseStakeOptions = (stakes: string): Array<{number: number; content: string}> => {
    if (!stakes) return [];
    
    // Split the text into intro and options
    const [intro, ...optionParts] = stakes.split(/Option \d+\./);
    
    const options = optionParts.map((part, index) => {
      return {
        number: index + 1,
        content: part.trim()
      };
    });
  
    // If there's an intro text, add it as context
    const result = [];
    if (intro && intro.trim()) {
      result.push({
        number: 0, // Use 0 for intro/context
        content: intro.trim()
      });
    }
    
    return [...result, ...options];
  };


  if (!challenge) {
    return <div>Challenge not found</div>;
  }

  const stakeOptions = parseStakeOptions(challenge.stakes);

  

  return (
    <div className={`min-h-screen flex flex-col ${theme === 'dark' ? 'bg-white text-black' : 'bg-white text-[#333333]'}`}>
      <Navbar />
      {challenge && (
        <MetaHead
          title={`${challenge.name} - Week ${challenge.week} Fantasy Challenge`}
          description={`Take on the ${challenge.name} fantasy football challenge for Week ${challenge.week}. ${challenge.subtitle || challenge.description || 'Compete in our weekly fantasy football challenges and make your league more exciting.'}`}
          image={typeof challenge.image === 'string' ? challenge.image : undefined}
          url={`https://fantasyloser.com/challenges/${challenge.id}`}
          type="article"
          keywords={[
            'weekly fantasy challenge',
            'fantasy football competition',
            'fantasy league challenge',
            'week ' + challenge.week + ' challenge',
            challenge.name.toLowerCase(),
            'fantasy football activity',
            'league engagement',
            'weekly fantasy task',
            'fantasy competition',
            'league participation'
          ]}
        />
      )}
      <main className="flex-grow">
        {/* Header Section */}
<div className="w-full bg-[#1a1a1a]">
<div className="w-full lg:container mx-auto px-2">
  <div className="flex flex-col lg:flex-row h-auto lg:h-[815px] lg:space-x-8">
    {/* Image container */}
    <div className="w-full lg:w-[713px] h-[461px] lg:h-[812px] order-first lg:order-last lg:ml-10">
      {typeof challenge.image === 'string' && challenge.image ? (
        <div className="w-full h-full">
          <img
            src={challenge.image}
            alt={challenge.name}
            className="w-full h-full object-cover"
          />
        </div>
      ) : (
        <div className="w-full h-full bg-gray-800 flex items-center justify-center">
          <span className="text-gray-400 text-lg">No image available</span>
        </div>
      )}
    </div>

      {/* Content Section */}
      <div className="w-full lg:w-1/2 pt-8 lg:pt-16 pb-8">
        <div className="flex flex-wrap items-center gap-3 mb-6">
          <span className="bg-[#18f7b1] text-black px-4 py-2 rounded-full text-lg font-bold">
            Week {challenge.week}
          </span>
          {challenge.status === 'active' && (
            <span className="bg-white text-black px-4 py-2 rounded-full text-lg font-bold">
              Active Challenge
            </span>
          )}
        </div>

        <h1 className="text-4xl lg:text-6xl font-bold mb-8 font-antonio text-white">
          {challenge.name}
        </h1>


        {renderTruncatedSubtitle(challenge.subtitle || challenge.description)}

        <div className="mt-8 flex flex-wrap gap-2">
          {challenge.positions.map((position) => (
            <span
              key={position}
              className="bg-[#18f7b1] text-black px-4 py-2 rounded-full text-sm font-medium"
            >
              {position}
            </span>
          ))}
        </div>
      </div>
    </div>
  </div>
</div>

        {/* Challenge Description Section */}
        <div className="container mx-auto px-4 py-12 max-w-4xl">
          <div className="space-y-12">
            <div>
              <h2 className="text-6xl font-bold mb-8 font-antonio">THE CHALLENGE</h2>
              <div className="text-xl leading-relaxed font-roboto text-gray-700 whitespace-pre-wrap">
                {challenge.description}
              </div>
            </div>

            {/* Stakes Section */}
            <div>
  <h2 className="text-6xl font-bold mb-8 font-antonio">THE STAKES</h2>
  <div className="space-y-6">
    {stakeOptions.map((option) => (
      <div 
        key={option.number} 
        className={`${
          option.number === 0 
            ? ' text-[#1a1a1a]' 
            : 'bg-gray-50'
        } rounded-xl p-6`}
      >
        {option.number === 0 ? (
          // Context/Intro section
          <div className="text-2xl leading-relaxed font-roboto text-[#1a1a1a]">
            {option.content}
          </div>
        ) : (
          // Regular option section
          <>
            <div className="flex items-center gap-3 mb-4">
              <span className="bg-[#18f7b1] text-black px-3 py-1 rounded-full text-lg font-bold font-antonio">
                Option {option.number}
              </span>
            </div>
            <p className="text-xl leading-relaxed text-gray-700 font-roboto">
              {option.content}
            </p>
          </>
        )}
      </div>
    ))}
  </div>
</div>

          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ChallengeDetailPage;